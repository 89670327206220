<template>
  <div style="height: 100%; overflow-y: auto !important;">
    <template v-if="!loading">
      <template v-if="items.length > 0">
        <branch-list-items :items="items" />
      </template>
      <template v-else>
        <no-items
          :title="'영업점이 없습니다' | translate"
        />
      </template>
    </template>
    <template v-else>
      <div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
        <progress-loading />
      </div>
    </template>
  </div>
</template>

<script>
    import {PATH} from "@/constants";
    import BranchListItems from "@/components/items/branch/BranchListItems";
    import NoItems from "@/components/items/NoItems";
    import ProgressLoading from "@/components/part/ProgressLoading";
    import Branch from "@/model/branch";

    export default {
        name: "BranchListTabsItems",
        components: {ProgressLoading, NoItems, BranchListItems},
        props: {
            accountId: {
                type: Number,
                default: undefined,
            },
            changeId: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                loading: false,
                items: [],
            }
        },
        watch: {
            changeId() {
                this.loadBranchList();
            },
        },
        created() {
            this.loadBranchList();
        },
        methods: {
            async loadBranchList(loading = true) {
                if (!this.accountId) return;
                if (this.loading) return;
                this.loading = loading;
                try {
                    const response = await this.$request(PATH.BRANCH.LIST)
                        .setObject({
                            accountId: this.accountId,
                        })
                        .wait(500)
                        .enqueue();
                    this.items = response.data.map(e => new Branch(e));
                } catch (error) {
                    console.error(error);
                }
                this.loading = false;
            },
        },
    }
</script>

<style scoped>

</style>
