import { render, staticRenderFns } from "./MobileAccount.vue?vue&type=template&id=f5cab644&scoped=true"
import script from "./MobileAccount.vue?vue&type=script&lang=js"
export * from "./MobileAccount.vue?vue&type=script&lang=js"
import style0 from "./MobileAccount.vue?vue&type=style&index=0&id=f5cab644&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5cab644",
  null
  
)

export default component.exports