<template>
  <div style="height: 100%; overflow-y: auto !important;">
    <template v-if="!loading">
      <div
        style="height: 40px; background: #0c98fe; line-height: 40px; margin: 5px; color: #fff; font-weight: bold;"
        @click="onClickAdd"
      >
        {{ '상담노트 추가' | translate }}
      </div>
      <template v-if="items.length > 0">
        <account-consult-list-items
          :items="items"
          @click:consult="onClickDetail"
          @click:consult-add="onClickAdd"
        />
      </template>
      <template v-else>
        <no-items
          :title="'작성된 내용이 없습니다.' | translate"
        />
      </template>
    </template>
    <template v-else>
      <div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
        <progress-loading />
      </div>
    </template>
  </div>
</template>

<script>
    import {PATH} from "@/constants";

    import NoItems from "@/components/items/NoItems";
    import ProgressLoading from "@/components/part/ProgressLoading";

    import AccountConsultListItems from "@/components/items/account/AccountConsultListItems.vue";
    import Permissions from "@/constants/permissions";

    export default {
        name: "ConsultNoteTabsItems",
        components: {AccountConsultListItems, ProgressLoading, NoItems},
        props: {
          accountName: null,
            accountId: undefined,
            changeId: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                loading: false,
                items: [],
            }
        },
        watch: {
            changeId() {
                this.loadConsultNoteList();
            },
        },
        created() {
            this.loadConsultNoteList();
        },
        methods: {
          onClickDetail(item) {
            this.backGuard.pop();
            const params = {
              accountLogId: !item ? 0 : item.accountLogId,
              entryPoint: 'account',
              accountId: item.accountId,
              permission: Permissions.EXPOSE_ACCOUNT_INFO
            }

            this.routerManager.push({
              name: "ConsultNoteDetail",
              params
            });
          },
          onClickAdd() {
            this.backGuard.pop();
            const params = {
              account: {
                accountId: this.accountId,
                accountName: this.accountName,
                permission: Permissions.EXPOSE_ACCOUNT_INFO
              }
            }
            this.routerManager.push({
              name: "ConsultNoteRegister", params
            });
          },
            async loadConsultNoteList(loading = true) {
                if (!this.accountId) return;
                if (this.loading) return;
                this.loading = loading;
                try {
                    const response = await this.$request(PATH.CONSULT_NOTE.ACCOUNT)
                        .setObject({
                            accountId: this.accountId,
                        })
                        .wait(500)
                        .enqueue();
                    this.items = response.data;
                } catch (error) {
                    console.error(error);
                }
                this.loading = false;
            },
        },
    }
</script>

<style scoped>

</style>
