<template>
  <div class="mobile-input">
    <label
      class="mobile-input-wrap"
      :class="{'required':required, 'required-blue': !required && requiredBlue}"
    >
      <div class="mobile-input-wrap-title">
        {{ label | translate }}
        <button
          v-if="!!description"
          class="mobile-input-wrap-title-btn"
          @click="showHideDescription"
        > <img src="@/assets/images/icon_description.svg">
        </button>
      </div>
      <div
        class="mobile-input-wrap-input"
      >
        <span
          v-if="!!subLabel"
          class="mobile-input-wrap-input-sublabel"
        >{{ subLabel | translate }}</span>
        <input
          v-model="computedInternalValue"
          v-bind="{...castAttr}"
          @click="openModal"
        >
        <span
          v-if="!!unit"
          class="mobile-input-wrap-input-unit"
        >{{ unit | translate }}</span>
      </div>
    </label>
    <div
      v-if="descriptionOn"
      class="mobile-input-description"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
import MobileBaseInput from "./MobileBaseInput";
import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
import {ETC} from "@/constants/etc-code";

export default {
  name: "MobileAccount",
  extends: MobileBaseInput,
  mixins: [ModalVuetifyUser],
  props:{
  },
  computed: {
    castAttr(){
      return this.$attrs
    },

    computedInternalValue: {
      get() {
        return this.internalValue
      },

      set(newValue) {
        return
      },
    },
  },
  methods: {
    openModal() {
      this.openModalVuetify(MODAL_VUETIFY_NAMES.FORM.PICKER_ACCOUNT, {
            fullscreen: true,
            transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
          }, {title: this.titleComputed,},
          (data) => {
            this.$emit('changeAccount', data)
          }
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-input{
  font-size: 0.8rem;
  padding: 0.325rem 0.5rem;
  border-bottom: 1px solid #dddddd;
  &-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &-title{
      display: flex;
      padding: 0.25rem;
      font-weight: normal;
      text-align: left;
      flex: 2;
      &-btn{
        color: #0c98fe;
        margin-left: 4px;
      }
    }
    &-input{
      display: flex;
      align-items: center;
      flex: 3;
      &-sublabel{
        overflow: auto;
        color: #6e6e6e;
        margin-right: 6px;
        &:after{
          content: ':';
        }
      }
      input{
        padding: 0.25rem;
        border: 1px solid #dddddd;
        text-align: right;
        width: 100%;
        flex: 1;
        &:focus{
          outline: none;
        }
        &:disabled{
          background: #dddddd;
        }
      }
      &-unit{
        overflow: auto;
        color: #6e6e6e;
        margin-left: 6px;
      }
    }
    &.required:before{
      content: '*';
      color: #ff0000;
      position: absolute;
      left: -3px;
    }
    &.required-blue:before{
      content: '*';
      color: #0000ff;
      position: absolute;
      left: -3px;
    }
  }
  &-description{
    font-size: 0.810rem;
    line-height: 1.2rem;
    color: #0c98fe;
    text-align: left;
    padding: 0 0.25rem;
    /*&:before{
      content: 'ⓘ';
    }*/
  }
}
</style>