<template>
  <v-list
    three-line
    flat
    class="pa-0 pb-12"
  >
    <v-list-item-group>
      <div
        v-for="(item,index) in items"
        :key="item.key"
      >
        <v-list-item
          v-ripple="false"
          class="px-0"
        >
          <v-list-item-content>
            <v-list-item-subtitle
              class="my-2 ml-3 font-weight-bold text-left"
              style="color: #333; font-size: 1rem;"
            >
              <!-- 영업점명 -->
              {{ item.raw.branchName }}
              <a
                :href="item.invalidPosition ? 'javascript:void(0)' : 'geo:' + item.raw.lat + ',' + item.raw.lng"
              >
                <!-- 지도 아이콘 -->
                <img
                  v-if="!item.invalidPosition"
                  style="width: 20px; margin-bottom: -3px;"
                  src="@/assets/images/main/svg/map_picker_icon.svg"
                >
                <img
                  v-else
                  style="width: 20px; margin-bottom: -3px;"
                  src="@/assets/images/main/svg/map_picker_icon_none.svg"
                >
              </a>
            </v-list-item-subtitle>

            <v-list-item-subtitle
              class="ml-3 mb-3 text-left"
              style="color: #333; font-size: 1rem;"
            >
              <!-- 주소 -->
              {{ item.raw.address }} {{ item.raw.addressDetail }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
    export default {
        name: "BranchListItems",
        components: {},
        props: {
            items: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {}
        },
        methods: {
            /**
             * 지도 아이콘이 클릭되었습니다.
             *
             * @param item
             */
            onLocationIconClick(item) {
            },
        },
    }
</script>

<style scoped>
    .v-list-item__subtitle {
        color: #333 !important;
        font-size: 0.875rem !important;
        text-align: left;
    }
</style>
