<template>
  <div class="viewer_content">
    <div
      style="all: initial"
      v-html="!!internalValue.terms ? internalValue.terms : undefined"
    >
      {{ internalValue.supplyCompanyName }}
    </div>
  </div>
</template>
<script>
/**
 * 전자계약서 화면
 */
import {MODAL_VUETIFY_NAMES} from "../../constants/vuetify-modal";
import {ETC} from "../../constants/etc-code";
import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";

export default {
  name: "ContractPrinterViewerForMobile",
  mixins: [ModalVuetifyUser],
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    context: undefined,
  },
  data() {
    return {
      isPrint: false,
      internalValue: undefined
    }
  },
  computed: {
    computedTaxType() {
      if (!this.internalValue?.taxType) return '포함'
      switch (this.internalValue.taxType) {
        case 'I':
          return '포함'
        case 'S':
          return '별도'
        case 'Z':
          return '영세'
        case 'F':
          return '면세'
        default :
          return '포함'
      }
    },
    /**
     * 임대업체 직인
     * @returns {undefined|*}
     */
    computedSeal() {
      if (!this.internalValue || !this.internalValue.sealUrl || this.internalValue.useSeal === 'N') return undefined
      return this.internalValue.sealUrl
    },

    /**
     * 계약자 서명
     * @returns {undefined|*}
     */
    computedSign() {
      if (!this.internalValue || !this.internalValue.supplySign) return undefined
      return this.internalValue.supplySign
    },

    /**
     * 동의 ㅇ부
     */
    agree: {
      get() {
        return this.internalValue.supplyAgree
      },
      set(newValue) {
        this.internalValue.supplyAgree = newValue
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.internalValue = newValue
      },
    },
    internalValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  mounted() {
    this.internalValue = this.value
  },
  unmounted() {
    this.internalValue = undefined
  },
  methods: {
    /**
     * 서명패드 로드
     */
    openModal() {
      if (this.context.resultStatus === 'T') return
      this.openModalVuetify(MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SIGN, {
          persistent: true,
          fullscreen: true,
          hideOverlay: true,
          transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
        }, {supplyChargeName: this.internalValue.supplyChargeName},
        (data) => {
          this.internalValue.supplySign = data.signImage
          this.internalValue.supplyChargeName = data.supplyChargeName
        }, MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SIGN + "-" + this.internalValue.contractId,
      );
    },
  }
}
</script>

<style scoped>
.viewer_content{
  padding: 4px;
}
</style>
