<template>
  <div style="height: 100%; overflow-y: auto !important;">
    <template v-if="!loading">
      <template>
        <no-items
          v-if="items.length <= 0"
          :title="'CMS 정보가 없습니다' | translate"
        />
        <cms-list-item
          :items="items"
          @click:cms="onClickCms"
        />
      </template>
    </template>
    <template v-else>
      <div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
        <progress-loading />
      </div>
    </template>
  </div>
</template>

<script>
import {PATH} from "@/constants";
import NoItems from "@/components/items/NoItems";
import VuexProps from "@/framework/vuex/VuexProps";
import ProgressLoading from "@/components/part/ProgressLoading";
import CmsListItem from "../../../../items/cms/CmsListItem";

export default {
  name: "CmsTabsItems",
  components: {CmsListItem, ProgressLoading, NoItems},
  mixins: [VuexProps],
  props: {
    accountId: {
      type: [Number, String],
      default: undefined,
    },
    changeId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      items: [],
    }
  },
  watch: {
    accountId: {
      immediate: true,
      handler() {
        this.loadCmsList();
      },
    },
  },
  methods: {
    async loadCmsList() {
      if (!this.accountId || this.loading) return;
      this.loading = true;
      try {
        const response = await this.$request(PATH.ELECTRICAL.CMS_TEMP_SELECT).setObject({accountId: this.accountId}).enqueue()
        this.items = response.data
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },


    /**
     * 계약을 클릭하였습니다. 상세페이지로 이동합니다.
     */
    onClickCms(item) {
      this.backGuard.pop();
      const params = {
        cmsMemberTempId: !item ? 0 : item.cmsMemberTempId,
        accountId: !item ? this.accountId : item.accountId,
      }
      this.routerManager.push({
        name: "CmsTempRegister",
        params
      });
    },
  },
}
</script>

<style scoped>

</style>