class AccountAutocomplete {
    _item;

    constructor(item) {
        this._item = item;
    }

    get raw() {
        return this._item;
    }

    get key() {
        return this.raw.accountId;
    }

    get fullname() {
        let t = this.raw.accountName;
        t += " [" + this.raw.accountId + "]";
        return t;
    }
}

export default AccountAutocomplete