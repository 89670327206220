<template>
  <div class="overflow-y-auto overflow-x-auto">
    <my-tool-bar :title="'CMS 동의서'">
      <template #buttons>
        <toolbar-save-email-action
          :context="cmsTempContract"
          @save="onSave"
          @mail-send="onMailSend"
        />
      </template>
    </my-tool-bar>
    <div
      ref="cmsPrinterViewerWrap"
      class="cms_printer_viewer_wrap"
    >
      <div v-if="agreementFile">
        <img
          :src="agreementFile"
        >
      </div>
      <hyo-sung-cms-printer-viewer
        v-else-if="!!cmsTempContract && cmsTempContract.serviceProvider === 'F'"
        ref="image1"
        v-model="cmsTempContract"
        :is-print="isPrint"
      />
      <the-bill-cms-printer-viewer
        v-else-if="!!cmsTempContract && cmsTempContract.serviceProvider === 'N'"
        ref="image2"
        v-model="cmsTempContract"
        :is-print="isPrint"
      />
    </div>
  </div>
</template>

<script>
/**
 * 임대계약 및 프린터 계약 화면입니다.
 */

import {PATH} from "@/constants";
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import MyToolBar from "../../app/toolbar/MyToolBar";
import ToolbarSaveEmailAction from "../../app/toolbar/ToolbarSaveEmailAction";
import Canvg, {presets} from "canvg";
import {DateTime} from "luxon";
import {MODAL_VUETIFY_NAMES} from "../../../constants/vuetify-modal";
import {ETC} from "../../../constants/etc-code";
import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
import HyoSungCmsPrinterViewer from "../../printer-viewer/HyoSungCmsPrinterViewer";
import TheBillCmsPrinterViewer from "../../printer-viewer/TheBillCmsPrinterViewer";
import html2canvas from "html2canvas";

export default {
  name: "ElectricalCms",
  components: {
    TheBillCmsPrinterViewer,
    HyoSungCmsPrinterViewer, ToolbarSaveEmailAction, MyToolBar
  },
  extends: WindowComponent,
  mixins: [ModalVuetifyUser],
  inheritAttrs: false,
  metaInfo: {
    meta: [
      {
        name: 'viewport',
        vmid: 'viewport',
        content: 'width=device-width, viewport-fit=cover, maximum-scale=3.0, minimum-scale=0.5'
      }
    ],
  },
  props: {
    accountId: undefined,
    cmsMemberTempId: undefined,
  },
  data() {
    return {
      cmsTempContract: undefined,
      context: undefined,
      isPrint: false,
      agreementFile: undefined,
    }
  },
  computed: {},
  mounted() {
    this.loadDataForElectrical()
  },
  unmounted() {
    this.cmsTempContract = undefined
    this.context = undefined
    this.isPrint = false
    this.agreementFile = undefined
  },
  methods: {
    async loadDataForElectrical() {
      const requestArr = []
      requestArr.push(this.$requestWithOverlay(PATH.RENTAL_COMPANY.INFO)
        .setObject({})
        .enqueue())
      requestArr.push(this.$requestWithOverlay(PATH.ELECTRICAL.CMS_TEMP_SELECT)
        .setObject({cmsMemberTempId: this.cmsMemberTempId, accountId: this.accountId})
        .enqueue())
      requestArr.push(this.$requestWithOverlay(PATH.CMS.GET_BANK_CODE + 'null')
        .enqueue())

      const res = await Promise.all(requestArr)
      this.cmsTempContract = {
        ...res[1].data[0],
        bankCodes: [...res[2].data],
        supplySign: undefined,
        supplySign2: undefined,
      }

      if (!this.cmsTempContract.rentalcompanyName || this.cmsTempContract.rentalcompanyName == null) {
        this.cmsTempContract.rentalcompanyName = res[0].rentalCompany.rentalcompanyName
        this.cmsTempContract.rentalcompanyCeoName = res[0].ceoDetail.userFullname
        this.cmsTempContract.rentalcompanyBusinessNo = res[0].company.businessNo
        this.cmsTempContract.rentalcompanyAddress = res[0].company.address + res[0].company.addressDetail
      }

      if (!!this.cmsTempContract && !!this.cmsTempContract.agreementFile && this.cmsTempContract.agreementFile.length > 0) {
        this.agreementFile = await this.getFileUrl(this.cmsTempContract.agreementFile[0])
      }
    },

    async onSave() {
      if (this.cmsTempContract.agreement1Yn !== 'Y' || this.cmsTempContract.agreement2Yn !== 'Y') {
        this.$snackbarError(this.$translate("약관을 읽고 [동의함]을 선택해 주세요"));
        return
      } else if (!this.cmsTempContract.supplySign) {
        this.$snackbarError(this.$translate("신청인의 서명이 필요합니다."));
        return
      } else if (this.cmsTempContract.requester !== this.cmsTempContract.payerName && !this.cmsTempContract.supplySign2) {
        this.$snackbarError(this.$translate("신청인과 예금주가 다를 경우 예금주의 서명이 필요합니다."));
        return
      }

      this.isPrint = true
      this.$nextTick(async () => {
        this.$refs.cmsPrinterViewerWrap.style.transform = 'scale(1)'
        const elmnt = this.cmsTempContract.serviceProvider === 'F' ? this.$refs.image1.$el : this.$refs.image2.$el
        const result = await html2canvas(elmnt, {useCORS: true, allowTaint: true, scale:1})

        this.isPrint = false

        this.$refs.cmsPrinterViewerWrap.style.transform = 'scale(0.5)'
        this.saveCmsTempContract(result.toDataURL('image/jpeg'))
      })
    },

    async saveCmsTempContract(imgData) {
      this.cmsTempContract.resultStatus = 'T'
      this.cmsTempContract.resultFrom = 'M'

      try {
        const res = await this.$requestWithOverlay(PATH.ELECTRICAL.CMS_TEMP_INSERT)
          .appendObject('context', this.cmsTempContract)
          .appendDataUri('attachment', imgData, 'cms동의서.jpeg')
          .appendFiles('detachment', null)
          .multipart()
          .enqueue();
        this.$snackbarSuccess(this.$translate("데이터를 저장하였습니다."));
        this.routerManager.back();
      } catch (e) {
        this.$snackbarError(this.$translate("데이터를 저장하지 못했습니다."));
      }
    },

    onMailSend() {
      this.openModalVuetify(MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SEND_MAIL, {
          persistent: true,
          fullscreen: true,
          hideOverlay: true,
          transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
        }, {supplyEmail: this.cmsTempContract.email},
        (data) => {
          this.cmsTempContract.emailList = data.emailList
          if (this.cmsTempContract.agreement1Yn === 'Y' && this.cmsTempContract.agreement2Yn === 'Y' && !!this.cmsTempContract.supplySign && (this.cmsTempContract.requester === this.cmsTempContract.payerName || !!this.cmsTempContract.supplySign2)) {
            this.isPrint = true
            this.$nextTick(async () => {
              const elmnt = this.cmsTempContract.serviceProvider === 'F' ? this.$refs.image1.$el : this.$refs.image2.$el
              const result = await html2canvas(elmnt, {useCORS: true, allowTaint: true})

              this.isPrint = false
              this.sendMailCms(result.toDataURL())
            })
          }
          this.sendMailCms()
        }, MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SEND_MAIL + "-" + this.cmsMemberTempId,
      );
    },

    async sendMailCms(imgData) {
      let {cmsTempContract} = this.cmsTempContract

      try {
        const res = await this.$requestWithOverlay(PATH.ELECTRICAL.CMS_TEMP_SEND_MAIL)
          .appendObject('context', this.cmsTempContract)
          .appendDataUri('attachment', imgData, 'cms동의서.jpeg')
          .appendFiles('detachment', null)
          .multipart()
          .enqueue();
        this.$snackbarSuccess(this.$translate("이메일 전송 완료했습니다."));
        this.routerManager.back();
      } catch (e) {
        this.$snackbarError(this.$translate("이메일 전송 실패했습니다."));
      }
    },


    async getFileUrl(file) {
      if (file) {
        const path = file.path;
        const name = file.originalFilename;
        const fileUrl = `${PATH.BASE_URL}resource/file2?path=${encodeURI(path)}&filename=${encodeURI(name)}`

        if (name.includes('.svg')) {
          return await this._svgToPng(fileUrl);
        } else {
          return fileUrl;
        }
      } else {
        return undefined;
      }
    },

    async _svgToPng(svg) {
      const canvas = new OffscreenCanvas(50, 50);
      const ctx = canvas.getContext('2d');
      const v = await Canvg.from(ctx, svg, presets.offscreen());
      v.resize(50, 50, 'xMidYMid meet');
      await v.render();
      const blob = await canvas.convertToBlob();
      return URL.createObjectURL(blob);
    },
  },
}
</script>

<style scoped>
  .cms_printer_viewer_wrap {
    margin-top: 56px;
    transform: scale(0.5);
    transform-origin: top left;
  }
</style>
