<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <my-tool-bar :title="t('거래처') + (!accountId ? t('등록') : t('상세'))">
      <template #buttons>
        <toolbar-save-action
          @save="onSave"
          @refresh="onRefresh"
        />
      </template>
    </my-tool-bar>
    <div style="position: relative; top: 56px; height: calc(100% - 56px);">
      <template v-if="!loading && !!editContext">
        <detail-context-menu
          v-if="accountId"
          :location="location"
          :tel-no="editContext.company.companyTelno"
          :title="editContext.account.accountName"
          :account="editContext"
        />
        <account-base
          v-model="editContext"
          :account-id="accountId"
        />
        <additional-information-view
          v-if="accountId"
          :expand="expanded"
        >
          <template #header>
            <additional-information-view-header :expand.sync="expanded">
              <account-detail-additional-information-header
                :account-id="accountId"
                :expand="expanded"
                :tab-num="key"
              />
            </additional-information-view-header>
          </template>
          <template #content>
            <additional-information-view-content :expand="expanded">
              <account-detail-additional-information-content
                :account-id="accountId"
                :account-name="context.account.accountName"
                :expanded="expanded"
                :tab-num="key"
              />
            </additional-information-view-content>
          </template>
        </additional-information-view>
      </template>
      <template v-else>
        <progress-loading />
      </template>
    </div>
  </div>
</template>

<script>
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import MyToolBar from "@/components/app/toolbar/MyToolBar";
import {MAXLENGTH, PATH} from "@/constants";
import VuexProps from "@/framework/vuex/VuexProps";
import ProgressLoading from "@/components/part/ProgressLoading";
import DetailUtil from "@/components/mixins/DetailUtil";
import GroupHeader from "@/assets/plugins/mps-form/providers/components/group/GroupHeader";
import CodeOptions from "@/components/mixins/CodeOptions";
import AdditionalInformationViewContent from "@/components/widgets/additional/AdditionalInformationViewContent";
import AccountDetailAdditionalInformationContent
  from "@/components/widgets/additional/slot/AccountDetailAdditionalInformationContent";
import DetailContextMenu from "@/components/widgets/additional/DetailContextMenu";
import REGEX from "@/constants/regex";
import BackGuardEditModeInterface from "@/components/windows/mixins/BackGuardEditModeInterface";
import ToolbarSaveAction from "../../app/toolbar/ToolbarSaveAction";
import AdditionalInformationView from "../../widgets/additional/AdditionalInformationView";
import AdditionalInformationViewHeader from "../../widgets/additional/AdditionalInformationViewHeader";
import AccountDetailAdditionalInformationHeader
  from "../../widgets/additional/slot/AccountDetailAdditionalInformationHeader";
import AccountBase from "./AccountBase";
import {cloneDeep} from "lodash";
import userManager from "@/assets/plugins/mps-common/user/user-manager";
import {ETC} from "@/constants/etc-code";
import Permissions from "@/constants/permissions";


export default {
  name: "AccountDetail",
  components: {
    AccountBase,
    AccountDetailAdditionalInformationHeader,
    AdditionalInformationViewHeader,
    AdditionalInformationView,
    ToolbarSaveAction,
    DetailContextMenu,
    AccountDetailAdditionalInformationContent,
    AdditionalInformationViewContent,
    ProgressLoading,
    MyToolBar
  },
  extends: WindowComponent,
  mixins: [VuexProps, DetailUtil, CodeOptions, BackGuardEditModeInterface],
  props: {
    accountId: undefined,
  },
  data() {
    return {
      expanded: false,
      loading: false,
      key: undefined,
      dummy: {
        account: {
          accountName: null,
          bizType: 'Y',
          contractFeeYn: 'Y',
          contractFee: 0,
          billDay: 1,
          calculationStartdate: 1,
          useYn: 'Y',
          vatYn: 'Y',
          unpaidRequestYn: 'N',
          taxIssueType: 'N',
          mainItemCode: '',
          mainItem: '',
          fax: '',
          memo: '',
          accLevelKey: '00'
        },
        accountChargeEmployeeList: [
          {
            userId: null,
            accountId: null
          }
        ],
        ceoDetail: {
          userFullname: '',
          userBirthday: ''
        },
        chargeDetail: {
          userFullname: '',
          userMobileno: '',
          userEmail: '',
          userBirthday: ''
        },
        company: {
          companyName: '',
          businessNo: '',
          address: '',
          addressDetail: '',
          zipCode: '',
          companyTelno: ''
        },
        charger: [],
        auto: {
          useYn:'N',
          useCmsYn:'N',
          cmsRetryCount:0,
          cmsRetryPeriod:0,
          useTaxYn: 'N',
          taxSchDay: '',
          roundDownUnit: 0,
          billDay: 1
        }
      },
      context: undefined,
      editContext: undefined,
    }
  },
  computed: {
    location() {
      if (!this.editContext || !this.editContext.company) return {lat: undefined, lng: undefined}
      return {
        lat: this.editContext.company.lat,
        lng: this.editContext.company.lng
      };
    },
    editPermission() {
      return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_REGIST_EDIT)
    }
  },
  watch: {
    context: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.editContext = cloneDeep(newValue)
      }
    },
    expanded: {
      immediate: false,
      deep: false,
      handler(newValue) {
        localStorage.setItem('ACCOUNT_DETAIL_EXPAND', newValue)
      }
    },
  },
  mounted() {
    if (!this.accountId) {
      this.dummy.accountChargeEmployeeList[0].userId = this.user.userId
      this.context = cloneDeep(this.dummy)
    } else {
      this.loadDetail();
      this.loadOptions();
    }
    if(this.historyManager.isBack) {
      this.expanded = JSON.parse(localStorage.getItem('ACCOUNT_DETAIL_EXPAND'))
      this.key = Number(localStorage.getItem('ACCOUNT_DETAIL_TAB'))
      this.backGuard.push(() => {
        this.expanded = false;
      });
    }

  },
  unmounted() {
    this.expanded = false
  },
  methods: {
    loadOptions() {
      this.loadBillDayOnlyNumberOptions();
    },

    async loadDetail() {
      this.loading = true;
      try {
        const requestArr = []
        requestArr.push(this.$request(PATH.ACCOUNT.GET)
          .setObject({
            accountId: this.accountId,
          })
          .enqueue())
        requestArr.push(this.$request(PATH.ACCOUNT.AUTOMATION)
          .setObject({
            accountId: this.accountId,
          })
          .enqueue())
        const response = await Promise.all(requestArr)
        this.context = {
          ...response[0],
          auto: response[1]
        }
      } catch (e) {
        this.$snackbarError(this.$translate("데이터를 가져오지 못했습니다."));
      }
      this.loading = false
    },


    async onRefresh() {
      this.$dialog()
        .message(this.$translate('편집중인 정보를 초기화합니다.'))
        .buttonNegative(this.$translate('취소'))
        .buttonPositive(this.$translate('초기화'), () => {
          this.editContext = cloneDeep(this.context)
          this.editSubContext = cloneDeep(this.subContext)
        })
        .build().show();
    },

    onSave() {
      if(!this.editPermission) {
        return this.$snackbarError(ETC.PERMISSION.ACCOUNT_REGISTER_EDIT);
      }
      const check = this.editContext.account.accountName
      if(!check || check.trim() === '') {
        return this.$snackbarError(this.$translate("필수 입력 항목을 확인 하세요"));
      }
      this.$dialog()
        .message(this.$translate('저장하시겠습니까?'))
        .buttonNegative(this.$translate('취소'))
        .buttonPositive(this.$translate('저장'), () => {
          this.updateDetail();
        })
        .build().show();
    },

    async updateDetail() {
      this.updating = true;
      try {
        const {auto, ...editContext} = this.editContext
        // 기본정보 저장
        const response = await this.$requestWithOverlay(PATH.ACCOUNT.ACCOUNT_INSERT_OR_UPDATE)
          .setObject(editContext)
          .enqueue();

        auto.accountId = response.account.accountId
        // 자동화 및 매출 정보 저장
        const response2 = await this.$requestWithOverlay(PATH.ACCOUNT.AUTOMATION_INSERT_OR_UPDATE)
          .setObject(auto)
          .enqueue();
        this.setEditMode(false);
        if(!this.accountId || this.accountId === 0){
          this.$snackbar(this.$translate("거래처 정보가 등록되었습니다."));
        }else {
          this.$snackbar(this.$translate("거래처 정보가 수정되었습니다."));
        }
        this.backGuard.pop(); // 업데이트에 성공하였다면 편집모드에서 설정된 백가드를 제거합니다.
        if(!this.accountId) {
          this.accountId = response.account.accountId
          this.routerManager.replace({name: "AccountDetail", params: {accountId: this.accountId}});
          // this.loadDetail()
        } else {
          this.loadDetail()
        }
      } catch (e) {
        this.$snackbarError(this.$translate("서버에서 에러가 발생하였습니다."));
      } finally {
        this.updating = false;
      }
    },
    onModifyModeClicked() {
      this.setEditMode(true);
    },
    setEditMode(enabled) {
      this.actionMode = enabled;
      this.editMode = enabled;
    },
  },
}
</script>

<style scoped>

</style>
