<template>
  <div
    ref="content"
    class="overflow-y-auto overflow-x-auto"
  >
    <my-tool-bar
      :title="'전자계약서'"
    >
      <template #buttons>
        <toolbar-save-email-action
          :context="context"
          :change-viewer="true"
          @save="onSave"
          @mail-send="onMailSend"
          @change-viewer="onChangeViewer"
        />
      </template>
    </my-tool-bar>
    <div
      v-if="!!electricalContract && !showMobileVersion"
      class="contract_printer_viewer_wrap"
    >
      <contract-printer-viewer
        v-model="electricalContract"
        :context="context"
      />
    </div>
    <div
      v-if="!!electricalContract && showMobileVersion"
      class="contract_printer_viewer_wrap2"
    >
      <contract-printer-viewer-for-mobile
        v-model="electricalContract"
        :context="context"
      />
    </div>
  </div>
</template>

<script>
/**
 * 임대계약 및 프린터 계약 화면입니다.
 */

import {PATH} from "@/constants";
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import VuexProps from "@/framework/vuex/VuexProps";
import ContractPrinterViewer from "../../printer-viewer/ContractPrinterViewer";
import ContractPrinterViewerForMobile from "../../printer-viewer/ContractPrinterViewerForMobile";
import MyToolBar from "../../app/toolbar/MyToolBar";
import ToolbarSaveEmailAction from "../../app/toolbar/ToolbarSaveEmailAction";
import Canvg, {presets} from "canvg";
import {cloneDeep} from "lodash";
import {DateTime} from "luxon";
import {MODAL_VUETIFY_NAMES} from "../../../constants/vuetify-modal";
import {ETC} from "../../../constants/etc-code";
import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";


export default {
  name: "ElectricalContract",
  components: {ToolbarSaveEmailAction, ContractPrinterViewer, ContractPrinterViewerForMobile, MyToolBar},
  extends: WindowComponent,
  mixins: [ModalVuetifyUser],
  props: {
    accountId: undefined,
    contractId: undefined,
    electricalContractId: undefined,
    item: undefined,
  },
  data() {
    return {
      electricalBaseInfo: {
        rentalCompany: undefined,
        accountInfo: undefined,
        automation: undefined,
        contract: undefined,
        subContract: undefined,
        terms: undefined,
        sealUrl: undefined,
      },
      electricalContract: undefined,
      context: undefined,
      showMobileVersion: false
    }
  },
  mounted() {
    if (!this.electricalContractId) this.loadDataForContract()
    else this.loadDataForElectrical()
  },
  unmounted() {
    this.electricalBaseInfo = {
      rentalCompany: undefined,
      accountInfo: undefined,
      automation: undefined,
      contract: undefined,
      subContract: undefined,
      terms: undefined,
      sealUrl: undefined,
    }
    this.electricalContract = undefined
  },
  methods: {
    async loadDataForContract() {
      const requestArr = []
      requestArr.push(this.$request(PATH.RENTAL_COMPANY.INFO)
        .setObject({})
        .enqueue())
      requestArr.push(this.$request(PATH.ACCOUNT.GET)
        .setObject({
          accountId: this.accountId,
        })
        .enqueue())
      requestArr.push(this.$request(PATH.ACCOUNT.AUTOMATION)
        .setObject({
          accountId: this.accountId,
        })
        .enqueue())
      requestArr.push(this.$request(PATH.CONTRACT.DETAIL)
        .setObject({contractId: this.contractId})
        .enqueue())
      requestArr.push(this.$request(PATH.PRINTER_CONTRACT.LIST)
        .setObject({contractId: this.contractId})
        .enqueue())
      requestArr.push(this.$request(PATH.TERMS_AND_CONDITIONS.SELECT)
        .setObject({})
        .enqueue()
      )
      requestArr.push(this.getOfficialSealUrl())

      const response = await Promise.all(requestArr)
      this.electricalBaseInfo.rentalCompany = response[0]
      this.electricalBaseInfo.accountInfo = response[1]
      this.electricalBaseInfo.automation = response[2]
      this.electricalBaseInfo.contract = response[3]
      this.electricalBaseInfo.subContract = response[4].data
      this.electricalBaseInfo.terms = response[5]
      this.electricalBaseInfo.sealUrl = response[6]

      this.setElectricalContractForContract();
    },

    async getOfficialSealUrl() {
      const res = await this.$requestWithOverlay(PATH.ACCOUNT.OFFICIAL_SEAL)
        .setObject({rentalcompanyId: this.userManager.user.rentalcompanyId})
        .enqueue();
      if (res?.data?.[0]) {
        const path = res.data[0].path;
        const name = res.data[0].originalFilename;
        const fileUrl = `${PATH.BASE_URL}resource/file2?path=${encodeURI(path)}&filename=${encodeURI(name)}`

        if (name.includes('.svg')) {
          return await this._svgToPng(fileUrl);
        } else {
          return fileUrl;
        }
      } else {
        return undefined;
      }
    },

    async _svgToPng(svg) {
      const canvas = new OffscreenCanvas(50, 50);
      const ctx = canvas.getContext('2d');
      const v = await Canvg.from(ctx, svg, presets.offscreen());
      v.resize(50, 50, 'xMidYMid meet');
      await v.render();
      const blob = await canvas.convertToBlob();
      return URL.createObjectURL(blob);
    },


    // 계약서 기반으로 조회 한 경우
    setElectricalContractForContract() {
      const accInfo = this.electricalBaseInfo.accountInfo
      const bizType = accInfo.account.bizType
      const contractInfo = this.electricalBaseInfo.contract
      const subContracts = this.electricalBaseInfo.subContract

      this.context = {
        electricalContractId: null,
        rentalcompanyId: contractInfo.rentalcompanyId,
        accountId: contractInfo.accountId,
        rentalContractId: contractInfo.contractId,
        resultStatus: null,
        resultFrom: null,
        jsonData: null,
      }

      const items = subContracts.map(e => {
        const fee = e.contractFeeYn === 'Y' ? e.contractFee : e.baseFee
        const data = {
          name: e.printerModelName,
          contractStartdate: e.contractStartdate,
          contractEnddate: e.contractEnddate,
          contractFee: !fee ? '-' : fee.toLocaleString(),
          basePagecount: !e.basePagecount ? '-' : e.basePagecount.toLocaleString(),
          basePagecountColor: !e.basePagecountColor ? '-' : e.basePagecountColor.toLocaleString(),
          additionalFee: !e.additionalFee ? '-' : e.additionalFee.toLocaleString(),
          additionalFeeColor: !e.additionalFeeColor ? '-' : e.additionalFeeColor.toLocaleString(),
          deposit: !e.deposit ? '-' : e.deposit.toLocaleString(),
          installLocation: e.installLocation,
          comment: e.comment
        }
        return data
      })

      this.electricalContract = {
        supplyCompanyName: accInfo.account.accountName,
        supplyNumber: bizType === 'Y' ? accInfo.company.businessNo : accInfo.ceoDetail.userBirthday,
        supplyCeoName: accInfo.ceoDetail.userFullname,
        supplyPhone: accInfo.company.companyTelno,
        supplyFax: accInfo.account.fax,
        supplyEmail: accInfo.chargeDetail.userEmail,
        supplyBillType: (
          !contractInfo.payType || contractInfo.payType === ''
            ? '-' : contractInfo.payType === 'C' ? '카드' : '계좌이체'
        ),
        supplyBillDay: `매월 ${contractInfo.billDay}일`,
        supplyAddress: accInfo.company.address + accInfo.company.addressDetail,
        supplyInstall: '',
        supplyAgree: 'N',
        supplyChargeName: accInfo.account.accountName,
        items,
        terms: this.electricalBaseInfo.terms.contents,
        termsAndConditionsId: this.electricalBaseInfo.terms.termsAndConditionsId,
        rentalcompanyId: this.electricalBaseInfo.rentalCompany.rentalCompany.rentalcompanyId,
        rentalcompanyName: this.electricalBaseInfo.rentalCompany.rentalCompany.rentalcompanyName,
        accountId: accInfo.account.accountId,
        contractId: contractInfo.contractId,
        useSeal: 'N',
        supplySign: undefined,
        contractDate: DateTime.fromSQL(contractInfo.contractDate).toFormat('yyyy년 MM월 dd일'),
        sealUrl: this.electricalBaseInfo.sealUrl,
        rentalcompanyEmployeeName: this.$userManager.user.name,
        companyComment1: this.electricalBaseInfo.rentalCompany.company.companyComment1,
        companyComment2: this.electricalBaseInfo.rentalCompany.company.companyComment2,
        taxType: accInfo.account.taxType
      }
    },

    async loadDataForElectrical() {
      const res = await this.$requestWithOverlay(PATH.ELECTRICAL.CONTRACT_SELECT_BY_ID)
        .setObject(this.electricalContractId)
        .enqueue();

      const supplySign = await this.getSignFileUrl(res.signFiles[0])
      this.electricalContract = {...JSON.parse(res.data), supplySign}
      this.context = {
        electricalContractId: res.electricalContractId,
        rentalcompanyId: res.rentalcompanyId,
        accountId: res.accountId,
        rentalContractId: res.contractId,
        resultStatus: res.resultStatus,
        resultFrom: res.resultFrom,
        jsonData: this.electricalContract,
      }
    },

    async getSignFileUrl(signFile) {
      if (signFile) {
        const path = signFile.path;
        const name = signFile.originalFilename;
        const fileUrl = `${PATH.BASE_URL}resource/file2?path=${encodeURI(path)}&filename=${encodeURI(name)}`

        if (name.includes('.svg')) {
          return await this._svgToPng(fileUrl);
        } else {
          return fileUrl;
        }
      } else {
        return undefined;
      }
    },


    onSave() {
      if (this.electricalContract.supplyAgree !== 'Y') {
        this.$snackbarError(this.$translate("약관을 읽고 [동의함]을 선택해 주세요"));
        return
      } else if (!this.electricalContract.supplySign) {
        this.$snackbarError(this.$translate("신청인(또는 대리인)의 서명이 필요합니다."));
        return
      }

      this.$dialog()
        .message(this.$translate('저장하시겠습니까?'))
        .buttonNegative(this.$translate('취소'))
        .buttonPositive(this.$translate('저장'), () => {
          this.saveElectricalContract()
        })
        .build().show();
    },

    async saveElectricalContract() {
      const {supplySign, ...electricalContract} = this.electricalContract
      this.context.jsonData = electricalContract
      this.context.resultStatus = 'T'
      this.context.resultFrom = 'M'

      try {
        const res = await this.$requestWithOverlay(PATH.ELECTRICAL.CONTRACT_INSERT)
          .appendObject('context', this.context)
          .appendDataUri('attachment', supplySign)
          .appendFiles('detachment', null)
          .multipart()
          .enqueue();
        this.routerManager.back();
      } catch (e) {
        this.$snackbarError(this.$translate("데이터를 저장하지 못했습니다."));
      }

    },

    onMailSend() {
      const {supplySign, ...electricalContract} = this.electricalContract
      this.context.jsonData = electricalContract

      this.openModalVuetify(MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SEND_MAIL, {
          persistent: true,
          fullscreen: true,
          hideOverlay: true,
          transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
        }, {supplyEmail: this.context.jsonData.supplyEmail},
        (data) => {
          this.context.emailList = data.emailList
          this.sendMailElectricalContract()
        }, MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SEND_MAIL + "-" + this.context.contractId,
      );
    },

    async sendMailElectricalContract() {
      let {supplySign, ...electricalContract} = this.electricalContract
      this.context.jsonData = electricalContract
      this.context.resultStatus = electricalContract.supplyAgree === 'Y' && !!supplySign ? 'T' : 'S'
      this.context.resultFrom = !this.context.resultFrom && electricalContract.supplyAgree === 'Y' && !!supplySign ? 'M' : this.context.resultFrom

      if (!!supplySign && supplySign != null && supplySign.indexOf(PATH.BASE_URL) > -1) {
        supplySign = null
      }

      try {
        const res = await this.$requestWithOverlay(PATH.ELECTRICAL.CONTRACT_SEND_MAIL)
          .appendObject('context', this.context)
          .appendDataUri('attachment', supplySign, '전자서명.png')
          .appendFiles('detachment', null)
          .multipart()
          .enqueue();
        this.$snackbarSuccess(this.$translate("이메일 전송 완료했습니다."));
        this.routerManager.back();
      } catch (e) {
        this.$snackbarError(this.$translate("이메일 전송 실패했습니다."));
      }
    },

    onChangeViewer() {
      this.showMobileVersion = !this.showMobileVersion
      this.$nextTick(() => {
        this.$refs.content.scrollTo({left: 0, top: 0});
      })
    }
  },
}
</script>

<style scoped>
.contract_printer_viewer_wrap {
  margin-top: 56px;
  transform: scale(0.5);
  transform-origin: top left;
}
.contract_printer_viewer_wrap2 {
  margin-top: 56px;
}
</style>
