import { render, staticRenderFns } from "./MainAccounts.vue?vue&type=template&id=20a5bd96&scoped=true"
import script from "./MainAccounts.vue?vue&type=script&lang=js"
export * from "./MainAccounts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a5bd96",
  null
  
)

export default component.exports