<template>
  <v-layout :align-center="true">
    <v-spacer />
    <v-btn
      v-if="changeViewer"
      icon
      @click="onChangeViewer"
    >
      <!--<v-icon>mdi-refresh</v-icon>-->
      <img src="@/assets/images/main/svg/button_viewer.svg">
    </v-btn>
    <v-btn
      v-if="!context.agreementFile || context.agreementFile.length < 1"
      icon
      @click="onSave"
    >
      <!--<v-icon>mdi-content-save</v-icon>-->
      <img src="@/assets/images/main/svg/button_Save.svg">
    </v-btn>
    <v-btn
      icon
      @click="onEmailSend"
    >
      <!--<v-icon>mdi-refresh</v-icon>-->
      <img src="@/assets/images/main/svg/button_email.svg">
    </v-btn>
  </v-layout>
</template>

<script>
export default {
  name: "ToolbarSaveEmailAction",
  props: {
    value: {
      type: String,
      default: ''
    },
    context: {
      type: Object,
      default: undefined
    },
    changeViewer: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      onGuard: false,
    }
  },
  computed: {},
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    // 저장 버튼이 눌렸습니다.
    onSave() {
      if (this.onGuard) return;
      if (this.context.resultStatus === 'T') return this.$snackbarError(this.$translate("이미 저장완료 된 계약서 입니다."));
      this.$emit('save');
    },
    // 이메일 전송 버튼이 눌러졌습니다.
    onEmailSend() {
      if (this.onGuard) return;
      this.$emit('mail-send');
    },
    // 이메일 전송 버튼이 눌러졌습니다.
    onChangeViewer() {
      if (this.onGuard) return;
      this.$emit('change-viewer');
    },
  },
}
</script>

<style scoped>

</style>
