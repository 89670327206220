<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <my-tool-bar
      :action-mode="actionMode"
      :title="title"
    >
      <template #buttons>
        <toolbar-save-action
          @save="onSave"
          @refresh="onRefreshClicked"
        />
      </template>
    </my-tool-bar>

    <div style="position: relative; top: 56px; height: calc(100% - 56px);">
      <m-form
        ref="form"
        :edit="true"
        :form-data.sync="formData"
        :form-info="formInfo"
        class="overflow-y-auto overflow-x-hidden"
        form-provider="mps"
      />
    </div>
  </div>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import MForm from "@/assets/plugins/mps-form/MForm";
    import {MAXLENGTH, PATH} from "@/constants";
    import VuexProps from "@/framework/vuex/VuexProps";
    import DetailUtil from "@/components/mixins/DetailUtil";
    import GroupHeader from "@/assets/plugins/mps-form/providers/components/group/GroupHeader";
    import CodeOptions from "@/components/mixins/CodeOptions";
    import ToolbarSaveAction from "@/components/app/toolbar/ToolbarSaveAction";
    import now from "@/assets/plugins/mps-common/time/now";

    /**
     * formInfo 의 key 입니다. 실제 v-model 키 값과 동일합니다.
     */
    const infoKeys = {
        account: {
            accountName: "account.accountName",
            bizType: "account.bizType",
            useYn: "account.useYn",
            mainItem: "account.mainItem",
            memo: "account.memo",
            billDay: "account.billDay",
            taxIssueType: "account.taxIssueType",
            calculationStartdate: "account.calculationStartdate",
            tradingStartdate: "account.tradingStartdate",
            vatYn: "account.vatYn",
            billTypeName: "account.billTypeName",
            fax: "account.fax",
        },
        company: {
            businessNo: "company.businessNo",
            companyTelno: "company.companyTelno",
            zipCode: "company.zipCode",
            address: "company.address",
            addressDetail: "company.addressDetail",
        },
        ceoDetail: {
            userFullname: "ceoDetail.userFullname",
            userBirthday: "ceoDetail.userBirthday",
        },
        chargeDetail: {
            userFullname: "chargeDetail.userFullname",
            userEmail: "chargeDetail.userEmail",
            userMobileno: "chargeDetail.userMobileno",
        },
        accountAsLastHistory: "AccountAsLastHistory",
        contractList: "ContractList",
    }


    export default {
        name: "AccountRegister",
        components: {
            ToolbarSaveAction,
            MForm, MyToolBar
        },
        extends: WindowComponent,
        mixins: [VuexProps, DetailUtil, CodeOptions],
        data() {
            return {
                alreadyLoaded: false,
                loading: false,
                updating: false,
                actionMode: false,
                editMode: false,
                formData: undefined,
                formInfo: [
                    {
                        component: GroupHeader,
                        propsData: {
                            title: this.$translate("업체정보"),
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('거래처명'),
                        key: infoKeys.account.accountName,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        rules: [
                            (data) => !!data.account.accountName || this.$translate('거래처명을 입력해 주세요'),
                        ],
                        options: {
                            maxlength: MAXLENGTH.ACCOUNT.NAME,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('계약상태'),
                        key: infoKeys.account.useYn,
                        read: (inEditMode, data) => {
                            return {
                                codeValue: data.account.useYnName,
                                codeKey: data.account.useYn,
                            }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.account.useYn = v.codeKey;
                            data.account.useYnName = v.codeValue;
                        },
                        formReadType: this.$mps.form.types.OPTIONS_PICKER,
                        formWriteType: this.$mps.form.types.OPTIONS_PICKER,
                        options: {
                            items: [
                                {codeKey: "Y", codeValue: this.$translate("계약중")},
                                {codeKey: "N", codeValue: this.$translate("계약 해지")},
                            ]
                        },
                        rules: [
                            // (data) => !!data.account.useYn || this.$translate('계약 상태를 선택해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate('납부유형'),
                        key: infoKeys.account.bizType,
                        read: (inEditMode, data) => {
                            return {
                                codeKey: data.account.bizType,
                            }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.account.bizType = v.codeKey;
                        },
                        formReadType: this.$mps.form.types.OPTIONS_PICKER,
                        formWriteType: this.$mps.form.types.OPTIONS_PICKER,
                        options: {
                            items: [
                                {codeKey: "Y", codeValue: this.$translate("사업자")},
                                {codeKey: "N", codeValue: this.$translate("개인")},
                            ]
                        },
                        rules: [
                            // (data) => !!data.account.bizType || this.$translate('납부유형을 선택해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate('대표자명'),
                        key: infoKeys.ceoDetail.userFullname,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        rules: [
                            // (data) => !!data.ceoDetail.userFullname || this.$translate('대표자를 입력해 주세요'),
                        ],
                        options: {
                            maxlength: MAXLENGTH.USER.NAME,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('사업자번호'),
                        key: infoKeys.company.businessNo,
                        if: (inEditMode, data) => data.account.bizType === "Y",
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        rules: [
                            // (data) => !!data.company.businessNo || this.$translate('사업자번호를 입력해 주세요'),
                        ],
                        confirmRules: [
                            async (data) => {
                                try {
                                    await this.$request(PATH.ACCOUNT.DUPLICATE_BUSINESSNO)
                                        .setObject({businessNo: this.formData.company.businessNo})
                                        .enqueue();
                                } catch (e) {
                                    throw new Error(this.$translate('중복된 사업자 번호입니다.'));
                                }
                                return true;
                            },
                        ],
                        options: {
                            type: "url",
                            maxlength: MAXLENGTH.COMPANY.BUSINESS_NO,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('생년월일'),
                        key: infoKeys.ceoDetail.userBirthday,
                        if: (inEditMode, data) => data.account.bizType === "N",
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        rules: [
                            // (data) => !!data.ceoDetail.userBirthday || this.$translate('생년월일을 입력해 주세요'),
                        ],
                        options: {
                            type: "number",
                            maxlength: MAXLENGTH.USER.BIRTHDAY,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('전화번호'),
                        key: infoKeys.company.companyTelno,
                        formReadType: this.$mps.form.types.PHONE_NUMBER,
                        formWriteType: this.$mps.form.types.PHONE_NUMBER,
                        rules: [
                            // (data) => !!data.company.companyTelno || this.$translate('전화번호를 입력해 주세요'),
                        ],
                        options: {
                            maxlength: MAXLENGTH.COMPANY.TELNO,
                        },
                    },
                    {
                        mutable: true,
                        title: 'FAX',
                        key: infoKeys.account.fax,
                        formReadType: this.$mps.form.types.PHONE_NUMBER,
                        formWriteType: this.$mps.form.types.PHONE_NUMBER,
                        options: {
                            maxlength: MAXLENGTH.ACCOUNT.FAX,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('주소'),
                        key: infoKeys.company.address,
                        read: (inEditMode, data) => null,
                        write: (data, v) => {
                            data.company.address = v.roadAddr || v.jibunAddr;
                            data.company.zipCode = v.zipNo;
                            data.company.addressDetail = null;
                            data.company.lat = v.lat;
                            data.company.lng = v.lng;
                        },
                        formReadType: this.$mps.form.types.ADDRESS_PICKER,
                        formWriteType: this.$mps.form.types.ADDRESS_PICKER,
                        rules: [
                            // (data) => !!data.company.address || this.$translate('주소를 입력해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate('상세주소'),
                        key: infoKeys.company.addressDetail,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        rules: [
                            // (data) => !!data.company.addressDetail || this.$translate('상세주소를 입력해 주세요'),
                        ],
                        options: {
                            maxlength: MAXLENGTH.ADDRESS.DETAIL,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate("대표품목"),
                        key: infoKeys.account.mainItem,
                        read: (inEditMode, data) => null,
                        write: (data, v) => {
                            data.account.mainItem = v.codeValue;
                        },
                        formReadType: this.$mps.form.types.RENTALCOMPANY_CODE_DIALOG_PICKER,
                        formWriteType: this.$mps.form.types.RENTALCOMPANY_CODE_DIALOG_PICKER,
                        options: {},
                        rules: [
                            // (data) => !!data.account.mainItem || this.$translate('대표품목을 입력해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate("메모"),
                        key: infoKeys.account.memo,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            maxlength: MAXLENGTH.ACCOUNT.MEMO,
                        },
                        fullWidthLabel: true,
                    },
                    {
                        component: GroupHeader,
                        propsData: {
                            title: this.$translate("거래처 담당자"),
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate("담당자"),
                        key: infoKeys.chargeDetail.userFullname,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        rules: [
                            // (data) => !!data.chargeDetail.userFullname || this.$translate('거래처 담당자를 입력해 주세요'),
                        ],
                        options: {
                            maxlength: MAXLENGTH.USER.NAME,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate("휴대폰"),
                        key: infoKeys.chargeDetail.userMobileno,
                        formReadType: this.$mps.form.types.PHONE_NUMBER,
                        formWriteType: this.$mps.form.types.PHONE_NUMBER,
                        rules: [
                            // (data) => !!data.chargeDetail.userMobileno || this.$translate('담당자 연락처를 입력해 주세요'),
                        ],
                        options: {
                            maxlength: MAXLENGTH.USER.MOBILENO,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate("이메일"),
                        key: infoKeys.chargeDetail.userEmail,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            type: "email",
                            maxlength: MAXLENGTH.USER.EMAIL,
                        },
                        rules: [
                            // (data) => !data.chargeDetail.userEmail || RegExp(REGEX.EMAIL).test(data.chargeDetail.userEmail) || this.$translate('올바른 이메일을 입력해주세요'),
                        ],
                    },
                    {
                        component: GroupHeader,
                        propsData: {
                            title: this.$translate("결제정보"),
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate("결제일"),
                        key: infoKeys.account.billDay,
                        read: (inEditMode, data) => {
                            return {
                                codeKey: data.account.billDay,
                            }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.account.billDay = v.codeKey;
                        },
                        formReadType: this.$mps.form.types.OPTIONS_PICKER,
                        formWriteType: this.$mps.form.types.OPTIONS_PICKER,
                        rules: [
                            // (data) => !!data.account.billDay || this.$translate('결제일을 입력해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate("매출월"),
                        key: infoKeys.account.taxIssueType,
                        read: (inEditMode, data) => {
                            return {
                                codeKey: data.account.taxIssueType,
                            }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.account.taxIssueType = v.codeKey;
                        },
                        formReadType: this.$mps.form.types.OPTIONS_PICKER,
                        formWriteType: this.$mps.form.types.OPTIONS_PICKER,
                        options: {
                            items: [
                                {codeKey: "N", codeValue: this.$translate("당월")},
                                {codeKey: "C", codeValue: this.$translate("전월")},
                            ]
                        },
                        rules: [
                            // (data) => !!data.account.taxIssueType || this.$translate('매출월을 입력해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate("계산시작일"),
                        key: infoKeys.account.calculationStartdate,
                        read: (inEditMode, data) => {
                            return {
                                codeKey: data.account.calculationStartdate,
                            }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.account.calculationStartdate = v.codeKey;
                        },
                        formReadType: this.$mps.form.types.OPTIONS_PICKER,
                        formWriteType: this.$mps.form.types.OPTIONS_PICKER,
                        options: {},
                        rules: [
                            // (data) => !!data.account.calculationStartdate || this.$translate('계산시작일을 입력해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate("거래_시작일"),
                        key: infoKeys.account.tradingStartdate,
                        write: (data, v) => {
                            let d = this.$parseDate(v);
                            data.account.tradingStartdate = !!d ? d.toISO() : null;
                        },
                        formReadType: this.$mps.form.types.DATE_PICKER,
                        formWriteType: this.$mps.form.types.DATE_PICKER,
                        options: {},
                        rules: [
                            // (data) => !!data.account.tradingStartdate || this.$translate('거래시작일을 입력해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate("부가세_포함"),
                        key: infoKeys.account.vatYn,
                        read: (inEditMode, data) => {
                            return {
                                codeKey: data.account.vatYn,
                            }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.account.vatYn = v.codeKey;
                        },
                        formReadType: this.$mps.form.types.OPTIONS_PICKER,
                        formWriteType: this.$mps.form.types.OPTIONS_PICKER,
                        options: {
                            items: [
                                {codeKey: "Y", codeValue: this.$translate("포함")},
                                {codeKey: "N", codeValue: this.$translate("별도")},
                            ]
                        },
                        rules: [
                            // (data) => !!data.account.vatYn || this.$translate('부가세_포함을 선택해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate("영수증정보"),
                        key: infoKeys.account.billTypeName,
                        read: (inEditMode, data) => {
                            return {
                                codeKey: data.account.billType,
                                codeValue: data.account.billTypeName,
                            }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.account.billType = v.codeKey;
                            data.account.billTypeName = v.codeValue;
                        },
                        formReadType: this.$mps.form.types.OPTIONS_PICKER,
                        formWriteType: this.$mps.form.types.OPTIONS_PICKER,
                        options: {
                            items: [
                                {codeKey: "Y", codeValue: this.$translate("발급")},
                                {codeKey: "N", codeValue: this.$translate("발급안함")},
                            ]
                        },
                        rules: [
                            // (data) => !!data.account.billType || this.$translate('영수증정보를 선택해 주세요'),
                        ],
                    },
                ],
            }
        },

        computed: {
            props() {
                return this.GET_PROPS("AccountRegister");
            },
        },

        watch: {
            formData: {
                deep: true,
                handler: function () {
                    this.updateOptions();
                    this.updatePropsData();
                }
            },
        },

        created() {
            this.initFormData();
            this.updatePropsData();
            this.updateOptions();
        },

        mounted() {
            this.loadOptions();
        },

        methods: {
            loadOptions() {
                this.loadBillDayOnlyNumberOptions();
            },

            updatePropsData() {
            },

            updateOptions() {
                // 주소 피커
                this.updateOptionByKey(infoKeys.company.address, () => {
                    return {
                        address: this.formData.company.address,
                        zipCode: this.formData.company.zipCode,
                        lat: this.formData.company.lat,
                        lng: this.formData.company.lng,
                    }
                });

                // 대표품목 피커
                this.updateOptionByKey(infoKeys.account.mainItem, () => {
                    return {
                        codeType: 'TXMDL',
                        codeValue: this.formData.account.mainItem,
                        defaultTitle: "대표품목 선택",
                    }
                });

                // 결제일 피커
                this.updateOptionByKey(infoKeys.account.billDay, () => {
                    return {
                        items: this.billDayOnlyNumberOptions,
                    }
                });

                // 계산시작일 피커
                this.updateOptionByKey(infoKeys.account.calculationStartdate, () => {
                    return {
                        items: this.billDayOnlyNumberOptions,
                    }
                });
            },

            async requestSave() {
                try {
                    const response = await this.$requestWithOverlay(PATH.ACCOUNT.REGISTER)
                        .setObject(this.formData)
                        .enqueue();

                    this.initFormData();

                    this.$dialog()
                        .message(this.$translate('거래처가 등록되었습니다. 계약서 등록을 진행하시겠습니까?'))
                        .buttonNegative(this.$translate('아니오'), () => {
                            this.$router.go(-1);
                        })
                        .buttonPositive(this.$translate('네'), () => {
                            // 계약서 등록 페이지 이동
                          this.routerManager.replace({
                            name: "ContractRegister",
                            params: {
                              contractId: 0,
                              contractNo: undefined,
                              accountId: response.account.accountId,
                            }
                          });
                        })
                        .build().show();

                } catch (e) {
                    this.$snackbarError(this.$translate("서버에서 에러가 발생하였습니다."));
                }
            },

            async onSave() {
                if (!await this.$refs.form.validate()) return;
                this.requestSave();
            },

            initFormData() {
                this.formData = {
                    account: {
                        accountId: null,
                        accountName: null,
                        bizType: "Y",
                        zipCode: null,
                        address: null,
                        billDay: null,
                        addressDetail: null,
                        memo: null,
                        mainItem: "",
                        billType: "N",
                        calculationStartdate: null,
                        tradingStartdate: now().toISO(),
                        taxIssueType: "N",
                        vatYn: "Y",
                        useYn: "Y",
                        staffFullname: null,
                        fax: null,
                    },
                    chargeDetail: {
                        userFullname: null,
                        userMobileno: null,
                        userEmail: null,
                        staffUserId: null,
                    },
                    ceoDetail: {
                        userFullname: null,
                        userBirthday: null,
                    },
                    company: {
                        zipCode: null,
                        address: null,
                        addressDetail: null,
                        businessNo: null,
                        companyTelno: null,
                    },
                };
            },

            onRefreshClicked() {
                this.$dialog()
                    .message(this.$translate('편집중인 정보를 초기화합니다.'))
                    .buttonNegative(this.$translate('취소'))
                    .buttonPositive(this.$translate('초기화'), () => {
                        this.initFormData();
                    })
                    .build().show();
            },
        },
    }
</script>

<style scoped>

</style>
