<template>
  <v-btn
    color="info"
    dark
    small
    absolute
    left
    fab
    elevation="0"
    width="50px"
    height="50px"
    @click="$emit('click:button')"
  >
    <v-icon>mdi-plus</v-icon>
  </v-btn>
</template>

<script>
    export default {
        name: "AddButton"
    }
</script>

<style scoped>
  .v-btn--absolute.v-btn--left {
    position: fixed;
    left: inherit;
    right: 10px;
    bottom: 65px;
    -webkit-backface-visibility: hidden;
  }
</style>