<template>
  <div class="fill-height">
    <v-tabs
      slot="extension"
      v-model="model"
      centered
      class="fill-height overflow-y-hidden"
      fixed-tabs
    >
      <v-tab
        v-for="(tab, index) in computedTabs"
        :key="'tab-' + index"
      >
        <div v-show="tab.title_bottom">
          {{ tab.title }} <br>
          {{ tab.title_bottom }}
        </div>
        <div v-show="!tab.title_bottom">
          {{ tab.title }}
        </div>
      </v-tab>
      <v-tabs-items
        v-model="model"
        class="fill-height overflow-y-hidden"
        style="transition: none;"
      >
        <v-tab-item
          :key="0"
          class="fill-height overflow-y-hidden"
        >
          <contract-tabs-items
            v-if="model === 0"
            :account-id="accountId"
            :change-id="changeId"
          />
        </v-tab-item>
        <v-tab-item
          :key="1"
          class="fill-height overflow-y-hidden"
        >
          <balance-tabs-items
            v-if="model === 1"
            :account-id="accountId"
            :change-id="changeId"
          />
        </v-tab-item>
        <v-tab-item
          :key="2"
          class="fill-height overflow-y-hidden"
        >
          <as-latest-tabs-items
            v-if="model === 2"
            :account-id="accountId"
            :change-id="changeId"
          />
        </v-tab-item>
        <v-tab-item
          :key="3"
          class="fill-height overflow-y-hidden"
        >
          <branch-list-tabs-items
            v-if="model === 3"
            :account-id="accountId"
            :change-id="changeId"
          />
        </v-tab-item>
        <v-tab-item
          :key="4"
          class="fill-height overflow-y-hidden"
        >
          <consult-note-tabs-items
            v-if="model === 4"
            :account-id="accountId"
            :account-name="accountName"
            :change-id="changeId"
          />
        </v-tab-item>
        <v-tab-item
          v-if="$userManager.countryFeature('KR')"
          :key="5"
          class="fill-height overflow-y-hidden"
        >
          <cms-tabs-items
            v-if="model === 5"
            :account-id="accountId"
            :change-id="changeId"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<style scoped>
.v-tab--active {
  color: #000 !important;;
  min-width: 100px;
}

.v-tab {
  color: #000 !important;
  min-width: 100px;
}

>>> .v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}
</style>

<script>
import BalanceTabsItems from "@/components/widgets/additional/slot/tabs-items/BalanceTabsItems";
import AsLatestTabsItems from "@/components/widgets/additional/slot/tabs-items/AsLatestTabsItems";
import ContractTabsItems from "@/components/widgets/additional/slot/tabs-items/ContractTabsItems";
import BranchListTabsItems from "@/components/widgets/additional/slot/tabs-items/BranchListTabsItems";
import CmsTabsItems from "./tabs-items/CmsTabsItems";
import ConsultNoteTabsItems from "@/components/widgets/additional/slot/tabs-items/ConsultNoteTabsItems.vue";
import {cloneDeep} from "lodash";

export default {
  name: "AccountDetailAdditionalInformationContent",
  components: {
    ConsultNoteTabsItems,
    CmsTabsItems, BranchListTabsItems, ContractTabsItems, AsLatestTabsItems, BalanceTabsItems},
  props: {
    accountId: null,
    accountName: null,
    expanded: {
      type: Boolean,
      default: false,
    },
    tabNum: undefined,
  },
  data() {
    return {
      model: "",
      tabs: [
        {
          title: this.$translate("계약정보"),
        },

        {
          title: `${this.$translate("매출")} /`,
          title_bottom: `${this.$translate("미수금액")}`
        },
        {
          title: this.$translate("최근 A/S"),
        },
        {
          title: this.$translate("영업점"),
        },
        {
          title: this.$translate("상담노트"),
        },
        {
          title: this.$translate("CMS(임시)"),
        },
      ],
      changeId: 0,
    }
  },
  computed: {
    computedTabs() {
      const spliceCnt = this.$userManager.countryFeature('KR') ? 6 : 5
      const result = cloneDeep(this.tabs).splice(0, spliceCnt)
      return result
    }
  },
  watch: {
    expanded(newValue) {
      if (newValue) {
        this.changeId++;
      }
    },
    tabNum: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.model = newValue
      }
    },
  },
  created() {
  },
  methods: {
  },
}
</script>
