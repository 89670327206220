<template>
  <!--  width: 210mm; min-height: 297mm;  -->
  <div style="margin: 0px auto; padding: 5mm; width: 210mm; min-height: 297mm;">
    <!--   height: 257mm;    -->
    <div style="padding: 0; margin: 0px auto;">
      <div style="padding: 4px;">
        <div style="font-weight: bold; font-size: 1rem; margin-bottom: 20px; text-align: center;">
          프린터 렌탈(임대)계약서
        </div>
        <div style="text-align: right; font-size: 0.875rem;">
          {{ internalValue.rentalcompanyName }}
        </div>
        <table
          style="border-spacing: 0; border-collapse: collapse; table-layout: fixed; width: 100%; margin-bottom: 10px;"
        >
          <tr>
            <th
              rowspan="6"
              style="width: 21px; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;"
            >
              신청정보
            </th>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              상호(계약자 명)
            </th>
            <td style="border: 1px solid #dddddd; font-size: 0.6875rem; text-align: left; padding: 2px;">
              {{ internalValue.supplyCompanyName }}
            </td>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              사업자번호(생년월일)
            </th>
            <td style="border: 1px solid #dddddd; font-size: 0.6875rem; text-align: left; padding: 2px;">
              {{ internalValue.supplyNumber }}
            </td>
          </tr>
          <tr>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              대표자명
            </th>
            <td style="border: 1px solid #dddddd; font-size: 0.6875rem; text-align: left; padding: 2px;">
              {{ internalValue.supplyCeoName }}
            </td>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              전화번호
            </th>
            <td style="border: 1px solid #dddddd; font-size: 0.6875rem; text-align: left; padding: 2px;">
              {{ internalValue.supplyPhone }}
            </td>
          </tr>
          <tr>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              FAX번호
            </th>
            <td style="border: 1px solid #dddddd; font-size: 0.6875rem; text-align: left; padding: 2px;">
              {{ internalValue.supplyFax }}
            </td>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              이메일
            </th>
            <td style="border: 1px solid #dddddd; font-size: 0.6875rem; text-align: left; padding: 2px;">
              {{ internalValue.supplyEmail }}
            </td>
          </tr>
          <tr>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              결제방법
            </th>
            <td style="border: 1px solid #dddddd; font-size: 0.6875rem; text-align: left; padding: 2px;">
              {{ internalValue.supplyBillType }}
            </td>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              결제일
            </th>
            <td style="border: 1px solid #dddddd; font-size: 0.6875rem; text-align: left; padding: 2px;">
              {{ internalValue.supplyBillDay }}
            </td>
          </tr>
          <tr>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              주소
            </th>
            <td
              colspan="3"
              style="border: 1px solid #dddddd; font-size: 0.6875rem; text-align: left; padding: 2px;"
            >
              {{ internalValue.supplyAddress }}
            </td>
          </tr>
        </table>

        <table
          style="border-spacing: 0; border-collapse: collapse; table-layout: fixed; width: 100%;"
        >
          <tr>
            <th
              :rowspan="internalValue.items.length + 2"
              style="width: 20px; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;"
            >
              계약물품
            </th>
            <th
              rowspan="2"
              style="width: 23px; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;"
            >
              #
            </th>
            <th
              rowspan="2"
              style="width: 32%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;"
            >
              품목명
            </th>
            <th
              rowspan="2"
              style="width: 90px; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;"
            >
              약정기간
            </th>
            <th
              rowspan="2"
              style="width: 80px; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;"
            >
              월 임대료(원)
            </th>
            <th
              colspan="2"
              style="width: 90px; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;"
            >
              기준매수(매)
            </th>
            <th
              colspan="2"
              style="width: 90px; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;"
            >
              추가료(원)
            </th>
            <th
              rowspan="2"
              style="width: 28%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;"
            >
              비고
            </th>
          </tr>
          <tr>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              흑백
            </th>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              컬러
            </th>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              흑백
            </th>
            <th style="width: 22%; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem;">
              컬러
            </th>
          </tr>
          <tr
            v-for="(item, index) in internalValue.items"
            :key="`contract-item-${index}`"
          >
            <td style="text-align: center; border: 1px solid #dddddd; font-size: 0.6875rem;  padding: 2px;">
              {{ index + 1 }}
            </td>
            <td style="text-align: left; border: 1px solid #dddddd; font-size: 0.6875rem;  padding: 2px;">
              {{ item.name }}<br>
              {{ item.deposit === '-' ? '' : `보증금: ${item.deposit} 원` }}
            </td>
            <td style="text-align: center; border: 1px solid #dddddd; font-size: 0.6875rem;  padding: 2px;">
              {{ item.contractStartdate }} <br> ~ {{ item.contractEnddate }}
            </td>
            <td style="text-align: right; border: 1px solid #dddddd; font-size: 0.6875rem;  padding: 2px;">
              {{ item.contractFee }}
            </td>
            <td style="text-align: right; border: 1px solid #dddddd; font-size: 0.6875rem;  padding: 2px;">
              {{ item.basePagecount }}
            </td>
            <td style="text-align: right; border: 1px solid #dddddd; font-size: 0.6875rem;  padding: 2px;">
              {{ item.basePagecountColor }}
            </td>
            <td style="text-align: right; border: 1px solid #dddddd; font-size: 0.6875rem;  padding: 2px;">
              {{ item.additionalFee }}
            </td>
            <td style="text-align: right; border: 1px solid #dddddd; font-size: 0.6875rem;  padding: 2px;">
              {{ item.additionalFeeColor }}
            </td>
            <td style="text-align: left; border: 1px solid #dddddd; font-size: 0.6875rem;  padding: 2px;">
              <strong v-if="!!item.installLocation">설치장소:</strong> {{ item.installLocation }}
              <br v-if="!!item.comment">{{ item.comment }}
            </td>
          </tr>
        </table>
        <div style="width: 100%; text-align: right; margin-bottom: 10px; font-size: 0.6875rem; color: #6666FF">
          (VAT {{ computedTaxType }})
        </div>

        <!--- 약관 내용 --->
        <div style=" border: 1px solid #dddddd; margin-bottom: 10px;">
          <div
            style="font-size: 0.6875rem; background-color: #f1f1f1; border-bottom: 1px solid #dddddd; padding: 2px; font-weight: bold; text-align: center;"
          >
            약관
          </div>
          <div
            style="padding: 4px; text-align: left; font-size: 0.625rem; overflow-x: auto; font-family: Segoe UI, Helvetica, Arial, sans-serif;"
            v-html="!!internalValue.terms ? internalValue.terms : undefined"
          />
        </div>

        <!--- 동의 --->
        <div style="font-size: 0.75rem; text-align: right">
          <div style="text-align: right; margin-bottom: 20px;">
            <span>위 계약내용 및 약관에</span>
            <label
              for="agree"
              style="padding: 4px !important;"
              :style="{'background': isPrint || context.resultStatus === 'T' ? '' : '#ffff00'}"
            >
              <input
                id="agree"
                v-model="agree"
                type="checkbox"
                :true-value="'Y'"
                :false-value="'N'"
                :disabled="context.resultStatus === 'T'"
                style="position: relative; top: 3px; margin: 0 6px;"
              >
              동의함
            </label>
            <label
              for="disagree"
              style="padding: 4px !important;"
              :style="{'background': isPrint || context.resultStatus === 'T' ? '' : '#ffff00'}"
            >
              <input
                id="disagree"
                v-model="agree"
                type="checkbox"
                :true-value="'N'"
                :false-value="'Y'"
                :disabled="context.resultStatus === 'T'"
                style="position: relative; top: 3px; margin: 0 6px;"
              >
              동의 안함
            </label>
          </div>

          <div style="text-align: center; font-size: 0.75rem; font-weight: bold; margin-bottom: 20px;">
            {{ internalValue.contractDate }}
          </div>

          <div>
            <table
              style="margin-bottom: 20px; width: 48%; border-spacing: 0; border-collapse: collapse; margin-left: auto;"
            >
              <tr>
                <th
                  colspan="2"
                  style="width: 44%; text-align: right; font-size: 0.75rem; padding: 2px 0 2px 16px; background-color: transparent; border: none; line-height: 2rem"
                >
                  서비스 공급자 (계약 담당자: {{ internalValue.rentalcompanyEmployeeName }})
                </th>
              </tr>
              <tr>
                <th
                  style="width: 44%; text-align: left; font-size: 0.75rem; padding: 5px 0 5px 16px; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.75rem;"
                >
                  {{ internalValue.rentalcompanyName }}
                </th>
                <td
                  style="font-size: 0.625rem; padding: 2px 16px 2px 2px; text-align: right; position: relative; border: 1px solid #dddddd;"
                  :style="{'background': isPrint || context.resultStatus === 'T' ? '' : '#ffff00'}"
                  @click="context.resultStatus !== 'T' ? internalValue.useSeal='Y' : undefined"
                >
                  {{ internalValue.useSeal === 'Y' ? '(인) 또는 서명' : '"이곳"을 클릭하면 직인이 적용됩니다.' }}
                  <div style="height: 50px; position: absolute; top: -12px; right: 15px;">
                    <img
                      v-if="!!computedSeal"
                      style="max-height: 100%;"
                      :src="computedSeal"
                      alt="직인"
                      @click.stop="context.resultStatus !== 'T' ? internalValue.useSeal = 'N' : undefined"
                    >
                  </div>
                </td>
              </tr>
            </table>

            <table
              style="margin-bottom: 20px; width: 48%; border-spacing: 0; border-collapse: collapse; margin-left: auto;"
            >
              <tr>
                <th
                  colspan="2"
                  style="width: 44%; text-align: right; font-size: 0.75rem; padding: 2px 0 2px 16px; background-color: transparent; border: none; line-height: 2rem"
                >
                  신청인 (또는 대리인)
                </th>
              </tr>
              <tr>
                <th
                  style="width: 44%; text-align: left; font-size: 0.75rem; padding: 5px 0 5px 16px; border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.75rem;"
                >
                  {{ internalValue.supplyChargeName }}
                </th>
                <td
                  style="font-size: 0.625rem; padding: 2px 16px 2px 2px; text-align: right; position: relative; border: 1px solid #dddddd;"
                  :style="{'background': isPrint || context.resultStatus === 'T' ? '' : '#ffff00'}"
                  @click.stop="openModal"
                >
                  (인) 또는 서명
                  <div
                    style="height: 60px; position: absolute; top: -17px; right: 15px; max-width: 200px; display: flex;"
                  >
                    <img
                      v-if="!!computedSign"
                      style="max-height: 100%;"
                      :src="computedSign"
                      alt="서명"
                    >
                  </div>
                </td>
              </tr>
              <tr v-show="!isPrint && context.resultStatus !== 'T'">
                <td colspan="2">
                  <div style="color: red;">
                    * 서명 등록 후 상단에 [저장]버튼을 클릭해 주세요.
                  </div>
                </td>
              </tr>
            </table>
            <div style="width: 100%; text-align: center;">
              {{ internalValue.companyComment1 }}
            </div>
            <div style="width: 100%; text-align: center;">
              {{ internalValue.companyComment2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * 전자계약서 화면
 */
import {MODAL_VUETIFY_NAMES} from "../../constants/vuetify-modal";
import {ETC} from "../../constants/etc-code";
import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";

export default {
  name: "ContractPrinterViewer",
  mixins: [ModalVuetifyUser],
  inheritAttrs: false,
  metaInfo: {
    meta: [
      {
        name: 'viewport',
        vmid: 'viewport',
        content: 'width=device-width, viewport-fit=cover, maximum-scale=3.0, minimum-scale=0.5'
      }
    ],
  },
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    context: undefined,
  },
  data() {
    return {
      isPrint: false,
      internalValue: undefined
    }
  },
  computed: {
    computedTaxType() {
      if (!this.internalValue?.taxType) return '포함'
      switch (this.internalValue.taxType) {
        case 'I':
          return '포함'
        case 'S':
          return '별도'
        case 'Z':
          return '영세'
        case 'F':
          return '면세'
        default :
          return '포함'
      }
    },
    /**
     * 임대업체 직인
     * @returns {undefined|*}
     */
    computedSeal() {
      if (!this.internalValue || !this.internalValue.sealUrl || this.internalValue.useSeal === 'N') return undefined
      return this.internalValue.sealUrl
    },

    /**
     * 계약자 서명
     * @returns {undefined|*}
     */
    computedSign() {
      if (!this.internalValue || !this.internalValue.supplySign) return undefined
      return this.internalValue.supplySign
    },

    /**
     * 동의 ㅇ부
     */
    agree: {
      get() {
        return this.internalValue.supplyAgree
      },
      set(newValue) {
        this.internalValue.supplyAgree = newValue
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.internalValue = newValue
      },
    },
    internalValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  mounted() {
    this.internalValue = this.value
  },
  unmounted() {
    this.internalValue = undefined
  },
  methods: {
    /**
     * 서명패드 로드
     */
    openModal() {
      if (this.context.resultStatus === 'T') return
      this.openModalVuetify(MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SIGN, {
          persistent: true,
          fullscreen: true,
          hideOverlay: true,
          transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
        }, {supplyChargeName: this.internalValue.supplyChargeName},
        (data) => {
          this.internalValue.supplySign = data.signImage
          this.internalValue.supplyChargeName = data.supplyChargeName
        }, MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SIGN + "-" + this.internalValue.contractId,
      );
    },
  }
}
</script>

<style scoped>
@page {
  size: A4;
  margin: 0;
}

@media print {
  #app {
    font-family: Segoe UI, Helvetica, Arial, sans-serif;
  }

  html, body {
    width: 210mm;
    height: 297mm;
  }

  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}


/*
  input[id="agree"],
  input[id="disagree"] {
    position: relative;
    top: -2px !important;
    margin: 0 6px;
  }
*/

/*input[type=checkbox] {
  display: inline-block;
}

input[type=checkbox] + label:before {
  content: none;
}*/
</style>
