<template>
  <div style="margin: 0px auto; padding: 5mm; width: 210mm; min-height: 297mm;">
    <div style="padding: 0; margin: 0px auto; height: 297mm;">
      <div style="padding: 4px;">
        <div>
          <div style="font-size: 1rem; color: #000000; font-weight: bold; margin-bottom: 16px; text-align: center;">
            효성 CMS 자동이체신청서
          </div>
          <div style="font-size: 0.625rem; font-weight: bold; text-align: right; margin-bottom: 10px;">
            금융기관 및 결제대행사(효성에프엠에스(주)) 제출용
          </div>
        </div>
        <div>
          <div style="text-align: left; font-size: 0.875rem; font-weight: bold; margin-bottom: 4px;">
            ◈ 수납업체 및 목적 (수납업체 기재란)
          </div>
          <table
            style="border-spacing: 0; border-collapse: collapse; width: 100%; table-layout: fixed; border: 2px solid #707070; margin-bottom: 10px;"
          >
            <tr>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 156px;">
                수납업체
              </th>
              <td style="word-break: break-all; border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.rentalcompanyName }}
              </td>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 156px;">
                수납목적
              </th>
              <td style="word-break: break-all; border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.reason }}
              </td>
            </tr>
            <tr>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 156px;">
                대표자
              </th>
              <td style="word-break: break-all; border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.rentalcompanyCeoName }}
              </td>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 156px;">
                사업자등록번호
              </th>
              <td style="word-break: break-all; border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.rentalcompanyBusinessNo }}
              </td>
            </tr>
            <tr>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 156px;">
                주소
              </th>
              <td
                colspan="3"
                style="border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;"
              >
                {{ internalValue.rentalcompanyAddress }}
              </td>
            </tr>
          </table>
        </div>
        <div>
          <div style="text-align: left; font-size: 0.875rem; font-weight: bold; margin-bottom: 4px;">
            ◈ 자동이체 신청내용 (신청고객 기재란)
          </div>
          <table
            style="border-spacing: 0; border-collapse: collapse; width: 100%; table-layout: fixed; border: 2px solid #707070;"
          >
            <tr>
              <th
                rowspan="2"
                style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 22px;"
              >
                신청정보
              </th>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 136px;">
                신청인
              </th>
              <td style="word-break: break-all; border: 1px solid #ddd; font-size: 0.6875rem; text-align: left; padding: 2px 4px;">
                <span style="margin-right: 40px;">
                  {{ internalValue.requester }}
                </span>
              </td>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 156px;">
                연락처
              </th>
              <td style="word-break: break-all; border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.payerPhone }}
              </td>
            </tr>
            <tr>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 136px;">
                납부금액
              </th>
              <td style="word-break: break-all; border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                <div v-if="!!internalValue.amount">
                  <span>고정금액: {{ internalValue.amount.toLocaleString() }}</span>원
                </div>
                <div v-else>
                  변동(추가 계약내용에 따름)
                </div>
              </td>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 156px;">
                납부일
              </th>
              <td style="word-break: break-all; border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                <div>
                  <span>매월 {{ internalValue.billDay }}</span>일
                </div>
              </td>
            </tr>
            <tr>
              <th
                rowspan="5"
                style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem;"
              >
                금융거래정보
              </th>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 136px;">
                납부방법
              </th>
              <td
                colspan="3"
                style="border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;"
              >
                {{ internalValue.paymentKind === 'B' ? '계좌이체' : '카드' }}
              </td>
            </tr>
            <tr>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 136px;">
                결제사명<br>(은행,카드사,통신사명)
              </th>
              <td style="word-break: break-all; border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                <div> {{ paymentBankName }}</div>
                <div v-if="internalValue.paymentKind === 'C'">
                  <span>카드결제 시 : 유효기간 (</span>
                  <span>{{ internalValue.cardValidDate.substring(0, 2) }}</span>월/
                  <span>{{ internalValue.cardValidDate.substring(2, 4) }}</span>년)
                </div>
              </td>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 156px;">
                결제자명
              </th>
              <td style="word-break: break-all; border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.payerName }}
              </td>
            </tr>
            <tr>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 136px;">
                결제번호<br>(게좌/카드/유무선번호)
              </th>
              <td
                colspan="3"
                style="border: 1px solid #ddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;"
              >
                {{ internalValue.paymentNumber }}
              </td>
            </tr>
            <tr>
              <th style="border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 136px;">
                결제자<br>휴대전화번호
              </th>
              <td
                colspan="3"
                style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;"
              >
                {{ internalValue.payerPhone }}
              </td>
            </tr>
            <tr>
              <th style="border: 1px solid #dddddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 136px;">
                <div>
                  사업자: 등록번호<br>개인: 생년월일 / 성별'
                </div>
              </th>
              <td
                colspan="3"
                style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;"
              >
                <div>
                  {{
                    internalValue.payerNumber
                  }}{{ internalValue.payerType === 'I' ? ` / ${internalValue.gender}` : '' }}
                </div>
              </td>
            </tr>
            <tr>
              <th
                rowspan="2"
                style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem;"
              >
                개인정보 활용동의
              </th>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 136px;">
                개인정보 수집 <br>및<br> 이용동의
              </th>
              <td
                colspan="3"
                style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;"
              >
                <div style="text-align: center; font-size: 0.6875rem; font-weight: bold; margin-bottom: 6px;">
                  ◇ 개인정보 수집 및 이용 동의 ◇
                </div>
                <div style="font-size: 0.5625rem;">
                  1. 수집 및 이용목적 : 효성CMS 자동이체를 통한 요금 수납
                </div>
                <div style="font-size: 0.5625rem;">
                  2. 수집항목 : 성명, 성별, 생년월일, 연락처, 결제사명, 결제자명, 계좌번호, 카드번호, 유효기간, 휴대/유선전화번호
                </div>
                <div style="font-size: 0.5625rem;">
                  3. 보유 및 이용기간 : 수집/이용 동의일부터 자동이체 종료일(해지일)까지
                </div>
                <div style="font-size: 0.5625rem;">
                  4. 신청자는 개인정보의 수집 및 이용을 거부할 수 있습니다. 단, 거부 시 자동이체 신청이 처리되지 않습니다.
                </div>
                <div style="text-align: right; margin-top: 10px;">
                  <span style="margin-right: 10px;">
                    <label
                      for="agree"
                      style="padding: 4px !important;"
                      :style="{'background': isPrint ? '' : '#ffff00'}"
                    >
                      <input
                        id="agree"
                        v-model="agree"
                        type="checkbox"
                        :true-value="'Y'"
                        :false-value="'N'"
                        style="position: relative; top: 3px; margin: 0 6px;"
                      >
                      동의함
                    </label>
                  </span>
                  <span>
                    <label
                      for="disagree"
                      style="padding: 4px !important;"
                      :style="{'background': isPrint ? '' : '#ffff00'}"
                    >
                      <input
                        id="disagree"
                        v-model="agree"
                        type="checkbox"
                        :true-value="'N'"
                        :false-value="'Y'"
                        style="position: relative; top: 3px; margin: 0 6px;"
                      >
                      동의 안함
                    </label>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th style="border: 1px solid #ddd; background-color: #f1f1f1; font-size: 0.6875rem; width: 136px;">
                개인정보 제3자<br>제공동의
              </th>
              <td
                colspan="3"
                style="font-size: 0.6875rem; padding: 2px 4px; text-align: left;"
              >
                <div style="text-align: center; font-size: 0.6875rem; font-weight: bold; margin-bottom: 6px;">
                  ◇ 개인정보 수집 및 이용 동의 ◇
                </div>
                <div style="font-size: 0.5625rem;">
                  1. 개인정보를 제공받는 자: 효성에프엠에스㈜, 금융기관(하단 신청가능은행 참조), 통신사(SKT, KT LGU+, LG헬로비전), 카드사(BC, 국민, 외환, 삼성, 신한, 롯데,
                  하나SK, 현대 등), 결제대행사(KG이니시스, 갤럭시아머니트리(주)), 효성ITX 등
                </div>
                <div style="font-size: 0.5625rem;">
                  2. 개인정보를 제공받는 자의 이용 목적: 자동이체서비스 제공 및 자동이체 동의 사실 통지, 고객센터 운영
                </div>
                <div style="font-size: 0.5625rem;">
                  3. 제공하는 개인정보의 항목 : 성명, 성별, 생년월일, 연락처, 결제사명, 결제자명, 계좌번호, 카드번호, 유효기간, 휴대/유선전화번호
                </div>
                <div style="font-size: 0.5625rem;">
                  4. 개인정보를 제공받는자의 개인정보 보유 및 이용기간: 동의일부터 자동이체의 종료일(해지일)까지. 단, 관계 법령에 의거 일정기간 동안 보관
                </div>
                <div style="font-size: 0.5625rem;">
                  5. 신청자는 개인정보에 대해 수납업체가 제 3자에 제공하는 것을 거부할 수 있습니다. 단, 거부 시 자동이체 신청이 처리되지 않습니다
                </div>
                <div style="text-align: right; margin-top: 10px;">
                  <span style="margin-right: 10px;">
                    <label
                      for="agree2"
                      style="padding: 4px !important;"
                      :style="{'background': isPrint ? '' : '#ffff00'}"
                    >
                      <input
                        id="agree2"
                        v-model="agree2"
                        type="checkbox"
                        :true-value="'Y'"
                        :false-value="'N'"
                        style="position: relative; top: 3px; margin: 0 6px;"
                      >
                      동의함
                    </label>
                  </span>
                  <span>
                    <label
                      for="disagree2"
                      style="padding: 4px !important;"
                      :style="{'background': isPrint ? '' : '#ffff00'}"
                    >
                      <input
                        id="disagree2"
                        v-model="agree2"
                        type="checkbox"
                        :true-value="'N'"
                        :false-value="'Y'"
                        style="position: relative; top: 3px; margin: 0 6px;"
                      >
                      동의 안함
                    </label>
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div style="border: 2px solid #707070; border-top: none; padding: 2px 4px;">
          <div style="font-size: 0.5625rem; font-weight: bold; text-align: left; margin-bottom: 8px;">
            자동이체 동의여부 통지 안내 : 효성에프엠에스㈜ 및 금융기관은 안전한 서비스의 제공을 위하여 결제자 휴대전화번호로 자동이체 동의 사실을 SMS(또는 LMS)로
            통지합니다.
          </div>
          <div style="font-size: 0.75rem; font-weight: bold; text-align: center; margin-bottom: 8px;">
            신청인(예금주)은 신청정보, 금융거래정보 등 개인정보의 수집·이용, 제 3자 제공 및 월자동납부에 동의하며<br>상기와 같이 신청합니다.
          </div>
          <div style="font-size: 0.8125rem; margin-bottom: 10px; font-weight: bold; text-align: center;">
            <span style=" font-size: 0.8125rem; font-weight: bold;">{{ applyDate }}</span>
          </div>
          <div
            style="text-align: right; position: relative;"
          >
            <div style="margin-bottom: 20px;">
              <span style="font-size: 0.8125rem; font-weight: bold;">신청인: </span>
              <span
                :style="{'background': isPrint ? '' : '#ffff00'}"
                @click.stop="openModal(0)"
              ><span
                 style="display: inline-block; width: 130px; border-bottom: 1px solid #333333; text-align: left"
               >
                 {{ internalValue.requester }}
               </span>
                <span style="font-size: 0.6875rem;">(인) 또는 서명</span>
                <div style="height: 40px; position: absolute; top: -15px; right: 0; max-width: 120px; display: flex;">
                  <img
                    v-if="!!computedSign"
                    style="max-height: 100%;"
                    :src="computedSign"
                    alt="서명"
                  >
                </div>
              </span>
            </div>
            <div
              style="position: relative;"
            >
              <span style="font-size: 0.6875rem; margin-right: 8px;">(신청인과 결제인이 다를 경우)</span>
              <span style="font-size: 0.8125rem; font-weight: bold;">결제인: </span>
              <span
                :style="{'background': isPrint ? '' : '#ffff00'}"
                @click.stop="openModal(1)"
              ><span style="display: inline-block; width: 130px; border-bottom: 1px solid #333333; text-align: left">
                 {{ internalValue.requester === internalValue.payerName ? '' : internalValue.payerName }}
               </span>
                <span style="font-size: 0.6875rem;">(인) 또는 서명</span>
                <div style="height: 40px; position: absolute; top: -15px; right: 0; max-width: 120px; display: flex;">
                  <img
                    v-if="!!computedSign2"
                    style="max-height: 100%;"
                    :src="computedSign2"
                    alt="서명"
                  >
                </div>
              </span>
            </div>
            <div
              v-if="!isPrint"
              style="color: red;"
            >
              * 서명 등록 후 상단에 [저장]버튼을 클릭해 주세요.
            </div>
          </div>
        </div>
        <div style="text-align: left; font-size: 0.5625rem; margin-top: 8px;">
          <div style="font-size: 0.5625rem;">
            1. 신청인과 결제인이 다른 경우 반드시 결제인의 별도 서명을 받아야 하며 인감 또는 서명은 출금통장의 사용인감 또는 서명을 사용해야 합니다.
          </div>
          <div style="font-size: 0.5625rem;">
            2. 기존 신청내용을 변경하고자 하는 경우에는 자동이체신청서를 신규로 작성하셔야 합니다.
          </div>
          <div style="font-size: 0.5625rem;">
            3.신청가능은행: 카카오뱅크, 케이뱅크, 신한, KEB하나, 농협, 국민, 우리, 기업, 대구, 새마을, 부산, 신협, 우체국, 경남, 광주, SC, 수협, 전북,
            씨티, 제주, 산업, 유안타증권, 삼성증권
          </div>
          <div style="font-size: 0.5625rem;">
            4.신청가능카드사 및 통신사: 국내외 전 카드사 이용가능. 단, 선불카드, 공무원복지카드, 무기명법인카드는 사용 불가 / SKT, KT, LGU+, LG헬로비전
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {MODAL_VUETIFY_NAMES} from "../../constants/vuetify-modal";
import {ETC} from "../../constants/etc-code";
import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";

export default {
  name: "HyoSungCmsPrinterViewer",
  mixins: [ModalVuetifyUser],
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    context: undefined,
    isPrint: {type: Boolean, default: false},
  },
  data() {
    return {
      internalValue: undefined
    }
  },
  computed: {
    computedSign() {
      if (!this.internalValue || !this.internalValue.supplySign) return undefined
      return this.internalValue.supplySign
    },
    computedSign2() {
      if (!this.internalValue || !this.internalValue.supplySign2) return undefined
      return this.internalValue.supplySign2
    },

    agree: {
      get() {
        return this.internalValue.agreement1Yn
      },
      set(newValue) {
        this.internalValue.agreement1Yn = newValue
      },
    },

    agree2: {
      get() {
        return this.internalValue.agreement2Yn
      },
      set(newValue) {
        this.internalValue.agreement2Yn = newValue
      },
    },

    paymentBankName() {
      if (!this.internalValue || !this.internalValue.bankCode || !this.internalValue.bankCodes) return
      return this.internalValue.bankCodes.find(e => this.internalValue.bankCode === e.codeKey).codeValue
    },

    applyDate() {
      if (!this.internalValue || !this.internalValue.regDatetime) return '-'
      return this.$parseDate(this.internalValue.regDatetime).toFormat('yyyy년 MM월 dd일')
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.internalValue = newValue
      },
    },
    internalValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  mounted() {
    this.internalValue = this.value
  },
  unmounted() {
    this.internalValue = undefined
  },
  methods: {
    openModal(index) {
      this.openModalVuetify(MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SIGN, {
          persistent: true,
          fullscreen: true,
          hideOverlay: true,
          transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
        }, {supplyChargeName: index === 0 ? this.internalValue.requester : this.internalValue.payerName},
        (data) => {
          if (index === 0) {
            this.internalValue.supplySign = data.signImage
            this.internalValue.requester = data.supplyChargeName
          }else {
            this.internalValue.supplySign2 = data.signImage
            this.internalValue.payerName = data.supplyChargeName
          }
        }, MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SIGN + "-" + this.internalValue.cmsMemberTempId,
      );
    },
  }
}
</script>

<style scoped>

@media screen {
  .web_font {
    font-size: 0.6875rem;
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }

  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  input[type=checkbox] + label:before {
    display: none;
  }
}


input[type=checkbox] + label:before {
  display: none;
}

input[type=checkbox] {
  display: inline-block;
  transform: translateY(1px);
}
</style>
