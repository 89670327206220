<template>
  <div style="margin: 0px auto; padding: 5mm; width: 210mm; min-height: 297mm; border: 1px solid #eee">
    <div style="padding: 0; margin: 0px auto; height: 297mm;">
      <div style="">
        <div>
          <div style="font-size: 1rem; font-weight: bold; text-align: center; margin-bottom: 1px;">
            자동이체 이용 신청서
          </div>
        </div>
        <div>
          <div style="text-align: left; font-size: 0.75rem; font-weight: bold; margin-bottom: 1px;">
            ◈ 수납기관 및 요금정보[수납기관 기재란]
          </div>
          <table style="border-spacing: 0; border-collapse: collapse; width: 100%; table-layout: fixed; border: 2px solid #707070; margin-bottom: 1px;">
            <tr>
              <th style="font-size: 0.6875rem; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                수납 기관명
              </th>
              <td style="font-size: 0.6875rem; padding: 2px 4px; text-align: left; border: 1px solid #dddddd;">
                {{ internalValue.rentalcompanyName }}
              </td>
              <th style="font-size: 0.6875rem; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                대표자명
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.rentalcompanyCeoName }}
              </td>
            </tr>
            <tr>
              <th style="font-size: 0.6875rem; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                사업장 주소
              </th>
              <td
                colspan="3"
                style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;"
              >
                {{ internalValue.rentalcompanyAddress }}
              </td>
            </tr>
            <tr>
              <th style="font-size: 0.6875rem; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                사업자등록번호
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.rentalcompanyBusinessNo }}
              </td>
              <th style="font-size: 0.6875rem; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                자동이체 사유
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.reason }}
              </td>
            </tr>
            <tr>
              <th style="font-size: 0.6875rem; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                출금통장 기재내역
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;" />
              <th style="font-size: 0.6875rem; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                수납 요금
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                <div v-if="!!internalValue.amount">
                  <span> {{ internalValue.amount.toLocaleString() }}</span>원
                </div>
                <div v-else>
                  변동(추가 계약내용에 따름)
                </div>
              </td>
            </tr>
            <tr>
              <th
                colspan="2"
                style="font-size: 0.6875rem; padding: 2px 4px; background-color: #f1f1f1; border: 1px solid #dddddd;"
              >
                납부자 번호(고객번호)
              </th>
              <th style="font-size: 0.6875rem; padding: 2px 4px; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                이체 시작 월
              </th>
              <th style="font-size: 0.6875rem; padding: 2px 4px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                지정 출금일
              </th>
            </tr>
            <tr>
              <td
                colspan="2"
                style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;"
              />
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: center;">
                {{ applyMonth }}
              </td>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: center;">
                매월 <span>{{ internalValue.billDay }}</span> 일
              </td>
            </tr>
          </table>
        </div>
        <div>
          <div style="text-align: left; font-size: 0.75rem; font-weight: bold; margin-bottom: 1px;">
            ◈ 자동이체 신청정보[신청인 기재란]
          </div>
          <table style="border-spacing: 0; border-collapse: collapse; width: 100%; table-layout: fixed; border: 2px solid #707070; margin-bottom: 1px;">
            <tr>
              <th style="font-size: 0.6875rem; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                신청인명
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.requester }}
              </td>
              <th style="font-size: 0.6875rem; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                예금(카드)주와의 관계
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.requester === internalValue.payerName ? '본인' : '대리인' }}
              </td>
            </tr>
            <tr>
              <th style="font-size: 0.6875rem; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                신청인 연락처
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;" />
              <th style="font-size: 0.6875rem; width: 156px; background-color: #f1f1f1; border: 1px solid #dddddd;">
                신청인 휴대번호
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.payerPhone }}
              </td>
            </tr>
            <tr>
              <th
                colspan="4"
                style="font-size: 0.6875rem; width: 156px; padding: 2px 4px; background-color: #f1f1f1; border: 1px solid #dddddd;"
              >
                자동이체 정보
              </th>
            </tr>
            <tr>
              <th style="font-size: 0.6875rem; background-color: #f1f1f1; border: 1px solid #dddddd;">
                출금이체 결제수단
              </th>
              <td
                colspan="3"
                style="border: 1px solid #dddddd; padding: 2px 4px; font-size: 0.6875rem; text-align: left;"
              >
                {{ internalValue.paymentKind === 'B' ? '계좌이체' : '카드' }}
              </td>
            </tr>
            <tr>
              <th style="font-size: 0.6875rem; background-color: #f1f1f1; border: 1px solid #dddddd;">
                예금주명<br>(신용카드 발급자명)
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.payerName }}
              </td>
              <th style="font-size: 0.6875rem; background-color: #f1f1f1; border: 1px solid #dddddd;">
                예금(카드)주 생년월일<br>(주민등록번호 앞 6자리)
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                <template v-if="internalValue.payerType === 'I'">
                  <span>{{ internalValue.payerNumber }}</span>-{{ internalValue.gender }}******
                </template>
              </td>
            </tr>
            <tr>
              <th style="font-size: 0.6875rem; background-color: #f1f1f1; border: 1px solid #dddddd;">
                은행(카드사)명
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ paymentBankName }}
              </td>
              <th style="font-size: 0.6875rem; background-color: #f1f1f1; border: 1px solid #dddddd;">
                사업자등록번호
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                <template v-if="internalValue.payerType === 'B'">
                  {{ internalValue.payerNumber }}
                </template>
              </td>
            </tr>
            <tr>
              <th style="font-size: 0.6875rem; background-color: #f1f1f1; border: 1px solid #dddddd;">
                계좌(카드)번호
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.paymentNumber }}
              </td>
              <th style="font-size: 0.6875rem; background-color: #f1f1f1; border: 1px solid #dddddd;">
                카드 유효기간
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                <template v-if="internalValue.paymentKind === 'C'">
                  <span>{{ internalValue.cardValidDate.substring(0, 2) }}</span>월/
                  <span>{{ internalValue.cardValidDate.substring(2, 4) }}</span>년
                </template>
              </td>
            </tr>
            <tr>
              <th style="font-size: 0.6875rem; background-color: #f1f1f1; border: 1px solid #dddddd;">
                예금주(카드) 연락처
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;">
                {{ internalValue.payerPhone }}
              </td>
              <th style="font-size: 0.6875rem; background-color: #f1f1f1; border: 1px solid #dddddd;">
                예금주(카드) 휴대번호
              </th>
              <td style="border: 1px solid #dddddd; font-size: 0.6875rem; padding: 2px 4px; text-align: left;" />
            </tr>
          </table>
        </div>
        <div>
          <div style="font-size: 0.6875rem; margin-bottom: 1px; text-align: left;">
            ※ 평생계좌(휴대폰번호)번호, 법인공용카드, 선불카드, 해외발행카드 등 일부 계좌 및 카드는 자동이체 서비스 신청이 불가합니다.
          </div>
          <table style="border-spacing: 0; border-collapse: collapse; width: 100%; table-layout: fixed; border: 2px solid #707070;">
            <tr>
              <th
                colspan="2"
                style="font-size: 0.6875rem; padding: 2px 4px; background-color: #f1f1f1; border: 1px solid #dddddd;"
              >
                자동이체 서비스 약관
              </th>
            </tr>
            <tr>
              <td style="border: 1px solid #dddddd; padding: 2px 4px;">
                <div
                  style="font-size: 0.5625rem; text-align: left;"
                >
                  1. 신청자는 본 신청서에 서명하거나 공인인증 및 그에 준하는 전자 인증 절차를 통함으로써 본 서비스를 이용할 수 있습니다.
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  2. 회사는 서비스 제공을 위하여 이용자가 제출한 지급결제 수단 정보를 해당 금융기관(통신사 포함)에 제공할 수 있습니다.
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  3.자동이체 개시일을 이용자가 지정하지 않은 경우 재화 등을 공급하는 자로부터 사전 통지받은 납기일 최초 개시일로 하며, 출금은 이용업체와 협의한 날짜에 계좌 출금이 이루어집니다.
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  4.자동이체 금액은 해당 지정 출금일 영업시간 내에 입금된 예금(지정 출금 일에 입금된 타점권은 제외)에 한하여 출금 처리되며, 출금이체 금액의 이의가 있는 경우에는 이용업체에 협의하여 조정키로 합니다.
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  5.납기 일에 동일한 수종의 자동이체 청구가 있는 경우 이제 우선순위는 이용자의 거래 금융기관이 정하는 바에 따릅니다.
                </div>
              </td>
              <td style="border: 1px solid #dddddd; padding: 2px 4px; vertical-align: top;">
                <div style="font-size: 0.5625rem; text-align: left;">
                  6. 자동이체 납부일이 영업일이 아닌 경우에는 다음 영업일을 납부일로 합니다.
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  7. 이용자가 자동이체 신청(신규, 해지, 변경)을 원하는 경우 해당 납기일 30일 전까지 회사에 통지해야 합니다.
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  8. 이용자가 제출한 지급결제수단의 잔액(예금한도, 신용한도 등)이 예정 결제금액보다 부족하거나 지급제한, 연체 등 납부자의 과실에 의해 발생하는 손해의 책임은 이용자에게 있습니다.
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  9. 이용자가 금융기관 및 회사가 정하는 기간 동안 자동이체 이용 실적이 없는 경우 사전 통지 후 자동이체를 해지할 수 있습니다.
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  10. 회사는 이용자와의 자동이체서비스 이용과 관련된 구체적인 권리, 의무를 정하기 위하여 본 약관과는 별도로 자동이체서비스이용약관을 제정할 수 있습니다.
                </div>
              </td>
            </tr>
            <td
              colspan="2"
              style="border: 1px solid #dddddd; padding: 2px 4px;"
            >
              <div style="font-size: 0.6875rem; font-weight: bold; text-align: left;">
                [개인정보 수집 및 이용 동의]
              </div>
              <div style="font-size: 0.5625rem; text-align: left;">
                1. 수집 및 이용목적 : 자동이체를 통한 요금 수납, 민원처리 및 동의여부 통지,
              </div>
              <div style="font-size: 0.5625rem; text-align: left;">
                2. 수집 항목 : 신청인(성명, 연락처, 휴대번호), 예금(카드)주명, 은행(카드)명, 계좌(카드)번호, 생년월일(사업자등록번호), 카드유효기간
              </div>
              <div style="font-size: 0.5625rem; text-align: left;">
                3. 보유 및 이용기간 : 수집 이용 동의일부터 자동이체서비스 종료일(해지일)까지며, 보유는 해지일로부터 5년간 보존 후 파기(관계 번령에 의거)
              </div>
              <div style="font-size: 0.5625rem; font-weight: bold; text-align: right;">
                신청자는 개인정보 수집 및 이용을 거부할 수 있습니다. 단, 거부하는 경우 자동이체 신청이 정상적으로 처리되지 않습니다.
              </div>
              <div style="text-align: right;">
                <span style="margin-right: 10px; position: relative;">
                  <label
                    for="agree"
                    style="font-size: 0.8125rem; font-weight: bold; padding-left: 10px!important;"
                    :style="{'background': isPrint ? '' : '#ffff00'}"
                  >
                    <input
                      id="agree"
                      v-model="agree"
                      type="checkbox"
                      :true-value="'Y'"
                      :false-value="'N'"
                      style="position: relative; top: 1px; margin: 0 6px;"
                    >
                    동의
                  </label>
                </span>
                <span>
                  <label
                    for="disagree"
                    style="font-size: 0.8125rem; font-weight: bold; padding-left: 10px!important;"
                    :style="{'background': isPrint ? '' : '#ffff00'}"
                  >
                    <input
                      id="disagree"
                      v-model="agree"
                      type="checkbox"
                      :true-value="'N'"
                      :false-value="'Y'"
                      style="position: relative; top: 1px; margin: 0 6px;"
                    >
                    동의 안함
                  </label>
                </span>
              </div>
            </td>
            <tr>
              <td
                colspan="2"
                style="border: 1px solid #dddddd; padding: 2px 4px;"
              >
                <div style="font-size: 0.6875rem; font-weight: bold; text-align: left;">
                  [개인정보 제3자 제공 동의]
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  1. 개인정보를 제공받는 자: 나이스페이먼츠 주식회사, 은행(신한/SC제일/씨티/하나/농협/기업/국민/저축/수협/신협/우체국/새마을금고/대구/부산/경남/광주/전북/조흥/제주/산업/케이뱅크/카카오뱅크), 카드사(국민/비씨/롯데/삼성/NH농협/현대/신한/하나/우리)
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  2. 개인정보를 제공받는 자의 개인정보 이용 목적: 자동이체 서비스 제공 및 출금동의 확인, 신규 등록 및 해지 사실 확인 및 통지, 민원 처리
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  3. 제공하는 개인정보의 항목: 신청(성명, 연락처, 휴대번호), 예금(카드)주명, 은행(카드)명, 계좌(카드)번호, 생년월일(사업자등록번호), 카드유효기간
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  4. 개인정보를 제공받는 자의 개인정보 보유 및 이용기간: 자동이체 신청부터 해지까지. 단, 관련 번령에 의거 일정기간 보유,
                </div>
                <div style="font-size: 0.5625rem; font-weight: bold; text-align: right;">
                  신청자는 개인정보 제3자 제공을 거부할 수 있습니다. 단, 거부하는 경우 자동이체 신청이 정상적으로 처리되지 않습니다.
                </div>
                <div style="text-align: right;">
                  <span style="margin-right: 10px; position: relative;">
                    <label
                      for="agree2"
                      style="font-size: 0.8125rem; font-weight: bold; padding-left: 10px!important;"
                      :style="{'background': isPrint ? '' : '#ffff00'}"
                    >
                      <input
                        id="agree2"
                        v-model="agree2"
                        type="checkbox"
                        :true-value="'Y'"
                        :false-value="'N'"
                        style="position: relative; top: 1px; margin: 0 6px;"
                      >
                      동의
                    </label>
                  </span>
                  <span>
                    <label
                      for="disagree2"
                      style="font-size: 0.8125rem; font-weight: bold; padding-left: 10px!important;"
                      :style="{'background': isPrint ? '' : '#ffff00'}"
                    >
                      <input
                        id="disagree2"
                        v-model="agree2"
                        type="checkbox"
                        :true-value="'N'"
                        :false-value="'Y'"
                        style="position: relative; top: 1px; margin: 0 6px;"
                      >
                      동의 안함
                    </label>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                style="border: 1px solid #dddddd; padding: 2px 4px;"
              >
                <div style="font-size: 0.6875rem; font-weight: bold; text-align: left;">
                  [자동이체 동의여부 및 해지사실 통지안내]
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  나이스페이먼츠 주식회사 및 금융기관(은행 및 카드사)은 자동이체 서비스의 안정적 운영을 위하여 고객의 연락처 정보를 활용하여 문자메시지, 유선 등으로 고객의 자동이체 등의 여부 및 해지사실을 통지할 수 있습니다.
                </div>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                style="border: 1px solid #dddddd; padding: 2px 4px;"
              >
                <div style="font-size: 0.6875rem; font-weight: bold; text-align: left;">
                  [금융거래정보의 제공동의서]
                </div>
                <div style="font-size: 0.5625rem; text-align: left;">
                  본 신청과 관련하여 본인은 위 기재한 금융거래정보 신청인(성명, 연락처), 예금주명, 예금주휴대폰번호, 출금은행명, 계좌번호, 예금주 생년월일(사업자등록번호)를 자동이체 신규 신청하는 때로부터 서면으로 해지 신청할 때까지 나이스페이먼츠 주식회사, 금융기관, 공공금융기관에 제공하는 것에 대하여 [금융실거래 및 비밀보장에 관한 법률]의 규정에 따라 동의합니다.
                </div>
              </td>
            </tr>
          </table>
          <div style="padding: 2px 4px;">
            <div style="text-align: left; font-size: 0.75rem; font-weight: bold; margin-bottom: 1px;">
              위와 같이 금융거래정보의 제공 및 개인정보 수집 및 이용, 개인정보 제 3자 제공에 동의하며, 자동이체 서비스 약관을 확인하고 자동이체 이용을 신청합니다.
            </div>
            <div style=" font-size: 0.8125rem; font-weight: bold; margin-bottom: 1px; text-align: center;">
              {{ applyDate }}
            </div>

            <div style="text-align: right; position: relative; margin-bottom: 16px;">
              <div style="margin-bottom: 20px;">
                <span style="font-size: 0.8125rem; font-weight: bold;">신청인: </span>
                <span
                  :style="{'background': isPrint ? '' : '#ffff00'}"
                  @click.stop="openModal(0)"
                ><span
                   style="display: inline-block; width: 130px; border-bottom: 1px solid #333333; text-align: left"
                 >
                   {{ internalValue.requester }}
                 </span>
                  <span style="font-size: 0.6875rem;">(인) 또는 서명</span>
                  <div style="height: 40px; position: absolute; top: -15px; right: 0; max-width: 120px; display: flex;">
                    <img
                      v-if="!!computedSign"
                      style="max-height: 100%;"
                      :src="computedSign"
                      alt="서명"
                    >
                  </div>
                </span>
              </div>
              <div
                style="position: relative;"
              >
                <span style="font-size: 0.8125rem; font-weight: bold;">예금(카드)주 동의: </span>
                <span
                  :style="{'background': isPrint ? '' : '#ffff00'}"
                  @click.stop="openModal(1)"
                ><span style="display: inline-block; width: 130px; border-bottom: 1px solid #333333; text-align: left">
                   {{ internalValue.payerName }}
                 </span>
                  <span style="font-size: 0.6875rem;">(인) 또는 서명</span>
                  <div style="height: 40px; position: absolute; top: -15px; right: 0; max-width: 120px; display: flex;">
                    <img
                      v-if="!!computedSign2"
                      style="max-height: 100%;"
                      :src="computedSign2"
                      alt="서명"
                    >
                  </div>
                </span>
              </div>
              <div
                v-if="!isPrint"
                style="color: red;"
              >
                * 서명 등록 후 상단에 [저장]버튼을 클릭해 주세요.
              </div>
            </div>
            <div style="text-align: left; font-size: 0.75rem; font-weight: bold;">
              *신청인과 예금주/카드주가 상이한 경우에는 예금주/카드주의 동의가 필요하며, 날인 또는 서명은 출금통장의 거래날인, 서명 사용
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {MODAL_VUETIFY_NAMES} from "../../constants/vuetify-modal";
import {ETC} from "../../constants/etc-code";
import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";

  export default {
    name: "TheBillCmsPrinterViewer",
    mixins: [ModalVuetifyUser],
    props: {
      value: {
        type: Object,
        default: undefined,
      },
      context: undefined,
      isPrint: {type: Boolean, default: false},
    },
    data() {
      return {
        internalValue: undefined
      }
    },
    computed: {
      computedSign() {
        if (!this.internalValue || !this.internalValue.supplySign) return undefined
        return this.internalValue.supplySign
      },
      computedSign2() {
        if (!this.internalValue || !this.internalValue.supplySign2) return undefined
        return this.internalValue.supplySign2
      },

      agree: {
        get() {
          return this.internalValue.agreement1Yn
        },
        set(newValue) {
          this.internalValue.agreement1Yn = newValue
        },
      },

      agree2: {
        get() {
          return this.internalValue.agreement2Yn
        },
        set(newValue) {
          this.internalValue.agreement2Yn = newValue
        },
      },

      paymentBankName() {
        if (!this.internalValue || !this.internalValue.bankCode || !this.internalValue.bankCodes) return
        return this.internalValue.bankCodes.find(e => this.internalValue.bankCode === e.codeKey).codeValue
      },

      applyDate() {
        if (!this.internalValue || !this.internalValue.regDatetime) return '-'
        return this.$parseDate(this.internalValue.regDatetime).toFormat('yyyy년 MM월 dd일')
      },
      
      applyMonth() {
        if (!this.internalValue || !this.internalValue.regDatetime) return '-'
        return this.$parseDate(this.internalValue.regDatetime).toFormat('yyyy년 MM월')
      }
    },
    watch: {
      value: {
        immediate: true,
        deep: true,
        handler(newValue) {
          this.internalValue = newValue
        },
      },
      internalValue: {
        immediate: true,
        deep: true,
        handler(newValue) {
          this.$emit('input', newValue)
        },
      },
    },
    mounted() {
      this.internalValue = this.value
    },
    unmounted() {
      this.internalValue = undefined
    },
    methods: {
      openModal(index) {
        this.openModalVuetify(MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SIGN, {
            persistent: true,
            fullscreen: true,
            hideOverlay: true,
            transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
          }, {supplyChargeName: index === 0 ? this.internalValue.requester : this.internalValue.payerName},
          (data) => {
            if (index === 0) {
              this.internalValue.supplySign = data.signImage
              this.internalValue.requester = data.supplyChargeName
            }else {
              this.internalValue.supplySign2 = data.signImage
              this.internalValue.payerName = data.supplyChargeName
            }
          }, MODAL_VUETIFY_NAMES.ELECTRICAL_CONTRACT.SIGN + "-" + this.internalValue.cmsMemberTempId,
        );
      },
    }
  }
</script>

<style scoped>

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
    }

    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }

    input[type=checkbox] + label:before {
      display: none;
    }
  }

  input[type=checkbox] + label:before {
    display: none;
  }

  input[type=checkbox] {
    display: inline-block;
    transform: translateY(1px);
  }
</style>
