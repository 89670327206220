<template>
  <v-list
    three-line
    flat
    class="pb-12"
  >
    <v-list-item-group>
      <v-list-item
        v-for="(item,index) in items"
        :key="'cms-'+item.cmsMemberTempId"
        v-ripple="false"
        class="pa-0"
        @click="onClick(item)"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-row
              justify="space-between"
              class="ma-0 pa-0 color__333 font__16"
            >
              <div>
                <span><!--{{ 'CMS 업체' | translate }} :-->{{ (item.serviceProvider === 'F' ? '효성' : '나이스')| translate }}</span>
              </div>
              <div>
                {{ item.nickname }}
              </div>
            </v-row>
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-row
              justify="space-between"
              class="mx-0 color__333 py__3"
            >
              <div>
                {{ '납부자명' | translate }} : {{ item.payerName }}
              </div>
              <div>
                {{ '결제방법' | translate }} : {{ (item.paymentKind === 'B' ? '계좌이체' : '카드' )| translate }}
              </div>
            </v-row>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-row
              justify="space-between"
              class="mx-0 color__333 py__3"
            >
              <div>
                {{ '연락처' | translate }} : {{ item.payerPhone }}
              </div>
              <div>{{ '상태' | translate }} : {{ item.status }}</div>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!--<v-divider />-->
      <div
        style="height: 40px; background: #0c98fe; line-height: 40px; margin: 5px; color: #fff; font-weight: bold;"
        @click="onClick()"
      >
        {{ '임시 CMS 추가' | translate }}
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import {ETC} from "@/constants/etc-code";
    import Permissions from "@/constants/permissions";

    export default {
        name: "CmsListItem",
        components: {},
        props: {
            items: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
            }
        },
      computed: {
        editPermission() {
          return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_REGIST_EDIT)
        }
      },
        methods: {
          onClick(item) {
            if(!this.editPermission) {
              return this.$snackbarError(ETC.PERMISSION.ACCOUNT_REGISTER_EDIT);
            }
            this.$emit("click:cms", item);
          },
        }
    }
</script>

<style scoped>
  >>> .v-list-item__content {
    padding: 10px 18px;
    border-bottom: 1px solid #ddd;

  }


</style>