<template>
  <v-list
    three-line
    flat
    class="pa-0 pb-12"
  >
    <v-list-item-group>
      <div
        v-for="(item,index) in items"
        :key="item.key"
      >
        <v-list-item
          v-ripple="false"
          class="px-0"
          @click="onClicked(item)"
        >
          <v-list-item-content class="mx-1">
            <v-list-item-title
              class="d-flex justify-space-between align-start"
            >
              <!-- 업체명 -->
              <div
                class="text-left font__14 black--text font-weight-bold"
                style="white-space: pre-line; word-break: break-word;"
              >
                <span>{{ '상담일' | translate }} : {{ item.writeDate | date }}
                </span>
              </div>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-row
                class="mx-0 d-flex flex-column font__14"
                style="text-align: left"
              >
                <span class="line_2_ellipsis">
                  {{ item.content || '-' }}</span>
                <span>{{ '작성일시' | translate }}: {{ item.regDatetime | datetime }}</span>
                <span>{{ '수정일시' | translate }}: {{ item.uptDatetime | datetime }}</span>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "AccountConsultListItems",
        components: {},
        props: {
            items: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {}
        },
        methods: {
          onClicked(item) {

            this.$emit("click:consult", item);
          },
          onAddClicked(item) {
            this.$emit("click:consult-add", item);
          },

        },
    }
</script>

<style scoped>
.line_2_ellipsis {
  color: black;
  font-size: 12px;
  white-space: pre-wrap;
  word-break: break-word;
  box-sizing: inherit;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

</style>
