<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <my-tool-bar
      :action-mode="actionMode"
      :searchable="true"
      :title="title| translate"
      :extended="extended"
    >
      <template #action>
        <toolbar-modify-action
          @close="tryToCloseEditMode"
          @save="onSave"
        />
      </template>
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
          @click="onModifyModeClicked"
        >
          <!--<v-icon>mdi-file-document-edit</v-icon>-->
          <img src="@/assets/images/main/svg/button_Edit.svg">
        </v-btn>
      </template>
    </my-tool-bar>
    <div style="position: relative; top: 56px; height: calc(100% - 56px);">
      <template>
        <mobile-group-header label="상세 정보" />
        <template v-if="!!formData">
          <m-form
            ref="form"
            :edit.sync="editMode"
            :form-data.sync="formData"
            :form-info="formInfo"
            class="overflow-y-auto"
            form-provider="mps"
          />
        </template>
      </template>
    </div>
    <over-max-size
      :base-param="formData"
      :detachments-list="[
        addFiles.detachments
      ]"
      :attachments-list="[
        addFiles.attachments]"
    />
  </div>
</template>

<script>


import {cloneDeep} from "lodash";
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import MyToolBar from "@/components/app/toolbar/MyToolBar";

import ToolbarModifyAction from "@/components/app/toolbar/ToolbarModifyAction.vue";
import BackGuardEditModeInterface from "@/components/windows/mixins/BackGuardEditModeInterface.vue";
import MobileGroupHeader from "@/components/new-comp/forms/MobileGroupHeader.vue";
import {ETC} from "@/constants/etc-code";
import DetailUtil from "@/components/mixins/DetailUtil.vue";
import ServerFile from "@/model/server-file";
import Attachment from "@/model/attachment";
import OverMaxSize from "@/components/app/toolbar/OverMaxSize.vue";
import {PATH} from "@/constants";

const infoKeys = {
  account: "accountId",
  writeDate: "writeDate"
}

export default {
  name: "ConsultNoteDetail",
  components: {OverMaxSize, MobileGroupHeader, ToolbarModifyAction, MyToolBar,},
  extends: WindowComponent,
  mixins: [BackGuardEditModeInterface, DetailUtil],
  props: { accountLogId: undefined, accountId: undefined, extended: undefined},
  data() {
    return {
      formData: undefined,
      actionMode: false,
      addFiles: {
        attachments: [],
        detachments: [],
      },

      loading: false,
      formInfo: [
        {
          mutable: false,
          title: this.$translate('거래처명'),
          key: infoKeys.account,
          read: (inEditMode, data) => null,
          write: (data, v) => {
            if (!v) return;
            data.accountId = v.accountId;
            data.accountName = v.accountName;
          },
          formReadType: this.$mps.form.types.ACCOUNT_PICKER,
          formWriteType: this.$mps.form.types.ACCOUNT_PICKER,
          options: {}
        },
        {
          mutable: true,
          title: this.$translate('상담일'),
          key: infoKeys.writeDate,
          write: (data, v) => {
            let d = this.$parseDate(v);
            data.writeDate = !!d ? d.toISO() : null;
          },
          formReadType: this.$mps.form.types.DATE_PICKER,
          formWriteType: this.$mps.form.types.DATE_PICKER,

        },
        {
          mutable: false,
          title: this.$translate('작성자'),
          key: 'registerName',
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {
          },
        },
        {
          mutable: false,
          title: this.$translate('작성일자'),
          key: 'regDatetime',
          formReadType: this.$mps.form.types.DATETIME_PICKER,
          formWriteType: this.$mps.form.types.DATETIME_PICKER,
          options: {
          },
        },
        {
          mutable: false,
          title: this.$translate('수정일자'),
          key: 'uptDatetime',
          formReadType: this.$mps.form.types.DATETIME_PICKER,
          formWriteType: this.$mps.form.types.DATETIME_PICKER,
          options: {
          },
        },
        {
          mutable: true,
          title: this.$translate('상담내용'),
          key: 'content',
          formReadType: this.$mps.form.types.FILE_UPLOAD,
          formWriteType: this.$mps.form.types.FILE_UPLOAD,
          updateData: (data, v) => {
            this.addFiles.attachments = data.attachments;
            this.addFiles.detachments = data.dettachments;
          },
          options: {
            listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
            accept: ETC.ACCEPT.IMAGE,
          },
        },
      ]
    }
  },
  computed: {
  },
  watch: {
    accountLogId: {
      immediate:true,
      handler() {
        this.loadDetail(true);
      }

    },

    formData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if(!newValue) return
        try {
          this.toAttachments(newValue)
        } catch (e) {
          console.error(e)
        }
        try {
          this.updateOptionsForFileUploadForm();
          this.updateOptions();
        } catch (e) {
          console.error(e)
        }


      }
    },
    
  },
  mounted() {
    localStorage.setItem('ACCOUNT_DETAIL_TAB', 5)
  },
  unmounted() {
    this.formData = undefined
    // this.editContext = undefined
  },


  methods: {
    async loadDetail(loading = true) {
      this.loading = loading;

      try {
        const response = await this.$request(PATH.CONSULT_NOTE.DETAIL+ `/${this.accountLogId}`)
            .enqueue();
        this.formData = response;
      } catch (e) {
        this.$snackbarError(this.$translate("데이터를 가져오지 못했습니다."));
      }
      this.loading = false

    },
    resetAttachments() {
      this.addFiles.attachments.splice(0, this.addFiles.attachments.length);
    },
    toAttachments(context) {
      this.resetAttachments();
      if (!!context.attachments && context.attachments.length > 0) {
        context.attachments.forEach(e => {
          let sf = new ServerFile(e);
          const attachment = new Attachment(sf);
          this.addFiles.attachments.push(attachment);
        });
      }

    },
    updateOptions() {
      // 거래처 피커
      this.updateOptionByKey(infoKeys.account, () => {
        return {
          accountId: this.formData.accountId,
          accountName: this.formData.accountName
        }
      });
    },
    updateOptionsForFileUploadForm() {
      this.updateOptionByKey("content", () => {
        return {
          attachments: this.addFiles.attachments,
          listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
          accept: ETC.ACCEPT.IMAGE,
        }
      });
    },

    onModifyModeClicked() {
      this.setEditMode(true);
    },
    setEditMode(enabled) {
      this.actionMode = enabled;
      this.editMode = enabled;
    },
    /**
     * 리셋 버튼 클릭
     * @returns {Promise<void>}
     */
    /*async onRefresh() {
      this.$dialog()
        .message(this.$translate('편집중인 정보를 초기화합니다.'))
        .buttonNegative(this.$translate('취소'))
        .buttonPositive(this.$translate('초기화'), () => {
          // this.editContext = cloneDeep(this.context)
        })
        .build().show();
    },*/

    /**
     * 저장 버튼 클릭
     * @returns {Promise<*>}
     */
    async onSave() {
      this.$dialog()
        .message(this.$translate('저장하시겠습니까?'))
          .buttonNegative(this.$translate('취소'))
        .buttonPositive(this.$translate('저장'), () => {
        this.onSaveClicked()
      }).build().show();
    },

    /**
     * 저장
     */
    onSaveClicked() {
      this.requestSave(false)
    },

    async requestSave(temp, workDate) {
      if (this.loading) return
      this.loading = true

      try {
        const response = await this.$requestWithOverlay(PATH.CONSULT_NOTE.UPDATE)
            .multipart()
            .appendObject('context', this.formData)
            .appendObject("dettachments", this.addFiles.detachments)
            .appendFiles("attachments", this.addFiles.attachments)
            .enqueue();
        this.$snackbar(this.$translate("수정되었습니다."));
        this.setEditMode(false);
        this.loadDetail();
        this.backGuard.pop(); // 업데이트에 성공하였다면 편집모드에서 설정된 백가드를 제거합니다.
      } catch (e) {
        console.error(e);
        this.$snackbarError(this.$translate("서버에서 에러가 발생하였습니다."));
      } finally {
          this.loading = false
          // this.loadData()
          this.$snackbar(this.$translate("수정되었습니다."));
        }

    },
  },
}
</script>

<style scoped>
.date_picker_wrap .v-input {
  margin: 0;
  padding-left: 10px;
  width: 110px;
}

.date_picker_wrap >>> .v-text-field__details {
  display: none;
}

.date_picker_wrap >>> .v-text-field input {
  padding: 9px;
  background-image: url(/img/dashboard_arrow.svg);
  background-repeat: no-repeat;
  background-position: 92% center;
  background-size: 8px;
  font-size: 0.917rem;
}

.date_picker_wrap >>> .v-text-field__slot {
  background-color: white;
  border: 1px solid #ccc;
  width: 50px;
  height: 34px;
  border-radius: 4px;
  margin-right: 2.8%;
}

.date_picker_wrap >>> .v-input__slot:before {
  display: none;
}

.date_picker_wrap >>> .v-input__slot:after {
  display: none;
}

select.form-select {
  text-align-last:center;
  background-color: white;
  border: 1px solid #ccc;
  width: 100px;
  height: 34px;
  border-radius: 4px;
  margin-right: 180px;
}

select.form-select > option {
  text-align: right;
  text-align-last: right;
  font-size: 1rem;
}

</style>
