<template>
  <div class="mobile-input">
    <div
      class="form-textarea__fieldset"
    >
      <textarea
        ref="textarea"
        v-model="computedInternalValue"
        v-bind="{...castAttr}"
        class="form-textarea"
        :style="textareaHeight"
      />
    </div>
    <div
      v-if="descriptionOn"
      class="mobile-input-description"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
import MobileBaseInput from "./MobileBaseInput";
import number from "../../../assets/plugins/mps-common/expansions/internal/number";
import TextAreaAutogrow from "@/assets/plugins/mps-form/providers/mixins/TextAreaAutogrow";

export default {
  name: "MpsTextarea",
  extends: MobileBaseInput,
  mixins: [TextAreaAutogrow],
  props:{
    row: undefined,
  },
  computed: {
    castAttr(){
      return this.$attrs
    },

    computedInternalValue: {
      get() {
        return this.internalValue
      },

      set(newValue) {
        this.internalValue = newValue
      },
    },
  },
    watch: {
      internalValue() {
        if (!this.internalValue || !this.$refs.textarea) return
        this.$refs.textarea.value = this.internalValue;
        this.autoGrow(this.$refs.textarea);
      }
    },
    mounted() {
      window.addEventListener("resize", e => {
        if (this.model) {
          this.model += " ";
          this.$nextTick(() => {
            this.model = this.model.slice(0, -1);
          });
        }
      });
      this.autoGrow(this.$refs.textarea);
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.mobile-input{
  font-size: 0.8rem;
  padding: 0.325rem 0.5rem;
  border-bottom: 1px solid #dddddd;
  &-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &-title{
      display: flex;
      align-items: center;
      padding: 0.25rem;
      font-weight: normal;
      text-align: left;
      flex: 2;
      &-btn{
        color: #0c98fe;
        margin-left: 4px;
      }
        & img {
          display: flex;
          align-items: center;
        }
    }
    &-input{
      display: flex;
      align-items: center;
      flex: 3;
      &-sublabel{
        overflow: auto;
        color: #6e6e6e;
        margin-right: 6px;
        &:after{
          content: ':';
        }
      }
      input{
        padding: 0.25rem 1rem 0.25rem 0.25rem;
        border: 1px solid #dddddd;
        text-align: right;
        width: 100%;
        flex: 1;
        &:focus{
          outline: none;
        }
        &:disabled{
          background: #dddddd;
        }
      }
      &-unit{
        overflow: auto;
        color: #6e6e6e;
        margin-left: 6px;
      }
    }
    &.required:before{
      content: '*';
      color: #ff0000;
      position: absolute;
      left: -3px;
    }
    &.required-blue:before{
      content: '*';
      color: #0c98fe;
      position: absolute;
      left: -3px;
    }
  }
  &-description{
    font-size: 0.810rem;
    line-height: 1.2rem;
    color: #0c98fe;
    text-align: left;
    padding: 0.25rem 0.25rem;
    /*&:before{
      content:url(/img/icon_description.svg);
    }*/
  }
  .form-textarea__fieldset {
    background: #fff;
    padding: 0.25rem 3.5rem 0.25rem 0.25rem;
    border: 1px solid #ddd;
    text-align: left;
  }

  /* TEXTAREA */
  textarea.form-textarea {
    text-align: inherit;
    width: 100%;
    overflow: hidden;
    display: block;
    margin: 0 0;
    /*padding: 8px 4px;*/
    padding: 0;
    font-size: 0.8rem;
    border: 0 !important;
    height: 24px;
  }
}
</style>