<template>
  <div>
    <!-- 인피니트 리스트 -->
    <vue-infinite-loader
      :items="items"
      :fetch="fetch"
      :identifier="identifier"
      item-key="raw.accountId"
    >
      <template #default="{ item }">
        <account-item-card
          :item="item"
          @click:item="onDetailClicked"
        />
      </template>
    </vue-infinite-loader>

    <!-- 추가 버튼 -->
    <add-button
      style="position: fixed; left: 10px; bottom: 65px;"
      @click:button="onAddClicked"
    />

    <!-- 새로고침 버튼 -->
    <refresh-button
      :container="mounted ? $refs.container : undefined"
      @click="onRefresh"
    />
  </div>
</template>

<script>
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import {PATH} from "@/constants";
import OnMainSearchTriggered from "@/event/OnMainSearchTriggered";
import Account from "@/model/account";
import OnMainFilterTriggered from "@/event/OnMainFilterTriggered";
import AddButton from "@/components/comp/AddButton";
import VuexProps from "@/framework/vuex/VuexProps";
import OnFilterResetListener from "@/framework/listeners/OnFilterResetListener";
import RefreshButton from "@/components/comp/RefreshButton";
import AccountItemCard from "@/components/items/account/AccountItemCard";
import Permissions from "@/constants/permissions";

export default {
  name: "MainAccounts",
  components: {AccountItemCard, RefreshButton, AddButton},
  extends: WindowComponent,
  mixins: [OnMainSearchTriggered, OnMainFilterTriggered, VuexProps, OnFilterResetListener],
  data() {
    return {
      items: [],
      searchOptions: {},
      identifier: 0,
      mounted: false,
    }
  },
  created() {
    this.initSearchOptions();
  },
  mounted() {
    this.mounted = true;
    if (this.historyManager.isForward) { // pushState 한 경우만 초기화
      // 마지막 필터 동작 내용 불러오기
      this.$emit('on-load-save-filter')
      // this.initSearchOptions();
      // this.identifier++;
    } else if (this.historyManager.isBack) {
      this.$emit('on-load-save-filter')
      this.loadAllUpToCurrent();
    }
  },
  methods: {
    onDetailClicked(item) {
      this.routerManager.push({
        name: "AccountDetail", params: {
          accountId: item.raw.accountId,
          permission: Permissions.EXPOSE_ACCOUNT_INFO
        }
      });
    },

    async loadAllUpToCurrent() {
      const identifier = this.identifier;
      const response = await this.$request(PATH.ACCOUNT.LIST)
        .setObject({
          ...this.searchOptions,
          pageNumber: 1,
          pageSize: this.searchOptions.pageNumber * this.searchOptions.pageSize,
        })
        .wait(500)
        .enqueue();
      if (identifier !== this.identifier) return;
      this.items = response.data.map(e => new Account(e));
    },

    async fetch(page, pageSize) {
      this.searchOptions.pageNumber = page;
      this.searchOptions.pageSize = pageSize;

      const response = await this.$request(PATH.ACCOUNT.LIST)
        .setObject(this.searchOptions)
        .catch()
        .wait(500)
        .enqueue();
      return response.data.map(e => new Account(e));
    },

    /**
     * 검색키워드와 필터 조건을 초기화 합니다.
     */
    initSearchOptions() {
      this.searchOptions = {};
      this.initFilterState();
    },

    /**
     * 필터 조건을 초기화 합니다.
     */
    initFilterState() {
      this.searchOptions.useYn = 'Y';
    },

    /**
     * 툴바 검색 버튼이 눌렸습니다.
     * @param keyword
     */
    onMainSearchTriggered(keyword) {
      this.searchOptions.searchKeyword = keyword;
      this.identifier++;
    },


    onMainFilterTriggered(state) {
      // this.searchOptions = {...this.searchOptions, ...state};

      const {searchKeyword, ...otherState} = state
      this.searchOptions = {...this.searchOptions, ...otherState};
      if (!!searchKeyword && searchKeyword !== '')
        this.searchOptions.searchKeyword = searchKeyword;
      this.identifier++;
    },

    /**
     * 추가 버튼이 눌러졌습니다.
     */
    onAddClicked() {
      this.routerManager.push({
        name: "AccountDetail", params: {
          accountId: 0,
          permission: Permissions.EXPOSE_ACCOUNT_INFO_REGIST_EDIT
        }
      });
    },

    /**
     * 필터 조건을 초기화하고 리로드합니다.
     */
    onFilterReset() {
      this.initSearchOptions();
      this.identifier++;
    },

    /**
     * 새로고침 버튼을 클릭하였습니다.
     *
     * 필터조건 변경없이 목록 데이터만 새로 로드합니다.
     */
    onRefresh() {
      this.identifier++;
    },
  }
}
</script>

<style scoped>
</style>
