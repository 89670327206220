<template>
  <div class="account_detail_list_wrap">
    <mobile-group-header
      label="업체정보"
      description="빨간 별(*)은 필수 입력 사항입니다."
    />
    <template v-if="!!internalValue">
      <mobile-input
        v-model="internalValue.account.accountName"
        label="거래처 명"
        required
      />
      <mobile-select
        v-if="!internalValue.account.accountId || internalValue.account.accountId < 1"
        v-model="internalValue.accountChargeEmployeeList[0].userId"
        label="A/S 담당기사"
        :items="employeeList"
      />
      <mobile-select
        v-model="internalValue.account.useYn"
        label="계약상태"
        :items="selectItems.useItem"
      />
      <mobile-select
        v-model="internalValue.account.bizType"
        label="납부유형"
        :items="selectItems.bizType"
      />
      <mobile-input
        v-model="internalValue.ceoDetail.userFullname"
        label="대표자명"
      />
      <mobile-input
        v-if="internalValue.account.bizType === 'Y'"
        v-model="internalValue.company.businessNo"
        label="사업자번호"
      />
      <mobile-input
        v-else
        v-model="internalValue.ceoDetail.userBirthday"
        label="생년월일"
        :placeholder="'주민번호 앞 6자리'"
      />
      <mobile-input
        v-model="internalValue.company.companyTelno"
        label="전화번호"
      />
      <mobile-input
        v-model="internalValue.account.fax"
        label="FAX"
      />
      <mobile-address
        :value="internalValue.company.address"
        label="주소"
        :placeholder="'주소를 검색하여 주세요'| translate"
        readonly
        @changeAddress="changeAddress"
      />
      <mobile-input
        v-model="internalValue.company.addressDetail"
        label="상세주소"
      />
      <mobile-select
        v-model="internalValue.account.mainItemCode"
        label="대표품목"
        :items="mainItemList"
      />
      <mobile-input
        v-model="internalValue.account.memo"
        label="메모"
      />
      <mobile-group-header
        label="거래처 담당자"
      />
      <mobile-input
        v-model="internalValue.chargeDetail.userFullname"
        label="담당자명"
      />
      <mobile-input
        v-model="internalValue.chargeDetail.userMobileno"
        label="연락처"
      />
      <mobile-input
        v-model="internalValue.chargeDetail.userEmail"
        label="이메일"
      />
      <mobile-group-header
        label="결제정보"
      />
      <mobile-select
        v-model="internalValue.auto.billDay"
        label="결제일"
        :items="itemsOfDay"
      />
      <mobile-select
        v-if="$userManager.countryFeature('KR')"
        v-model="internalValue.auto.cmsMemberId"
        label="CMS"
        :items="itemOfCmsMember"
      />
      <mobile-select
        v-if="$userManager.countryFeature('KR')"
        v-model="internalValue.auto.taxSchDay"
        label="세금계산서 발행일"
        :items="selectItems.addedTaxItems.concat(itemsOfDay)"
      />
      <mobile-select
        v-if="$userManager.countryFeature('KR')"
        v-model="internalValue.auto.tmId"
        label="세금계산서"
        :items="itemOfTaxMember"
      />
      <mobile-select
        v-if="$userManager.countryFeature('KR')"
        v-model="internalValue.auto.statementIssueDay"
        label="거래명세서 발행일"
        :items="selectItems.addedStatementItems.concat(itemsOfDay)"
      />
      <mobile-select
        v-if="$userManager.countryFeature('KR')"
        v-model="internalValue.auto.tsmId"
        label="거래명세서"
        :items="itemOfTransactionMember"
      />
      <mobile-select
        v-model="internalValue.auto.calculationStartdate"
        label="계산시작일"
        :items="itemsOfDay"
      />
    </template>
  </div>
</template>

<script>
/**
 * 거래처 기본 정보
 */
import {PATH} from "@/constants";

export default {
  name: "AccountBase",
  components: {},
  props: {
    accountId: undefined,
    value: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      internalValue: undefined,
      selectItems: {
        useItem: [
          {label: this.t('계약중'), value: 'Y', key: 'provider-type-1'},
          {label: this.t('계약해지'), value: 'N', key: 'provider-type-2'},
        ],
        bizType: [
          {label: this.t('사업자'), value: 'Y', key: 'provider-type-1'},
          {label: this.t('개인'), value: 'N', key: 'provider-type-2'},
        ],
        addedTaxItems: [
          {label: this.t('발행안함'), value: '', key: 'day-item-null'},
          {label: this.t('입금확인 후 익월발행'), value: '-1', key: 'day-item--1'},
        ],
        addedStatementItems: [
          {label: this.t('발행안함'), value: 0, key: 'day-item-null'},
        ],
        emptyItems: [
          {label: this.t('선택안함'), value: null, key: 'empty-item'},
        ]
      },
      requestData: {
        cmsMember: [],
        taxMember: [],
        transactionMember: [],
        codeList: [],
        employees: [],
      },
    }
  },
  computed: {
    itemsOfDay() {
      const result = []
      for (let i = 1; i < 32; i++) {
        result.push({label: `${i}${this.t('일')}`, value: i, key: `day-item-${i}`})
      }
      return result
    },

    itemOfCmsMember() {
      return this.selectItems.emptyItems.concat(this.requestData.cmsMember.map((e, idx) => {
        return {label: e.nickname, value: e.cmsMemberId, key: `cms-item-${idx}`}
      }))
    },
    itemOfTaxMember() {
      return this.selectItems.emptyItems.concat(this.requestData.taxMember.map((e, idx) => {
        return {label: e.nickname, value: e.tmId, key: `tax-item-${idx}`}
      }))
    },
    itemOfTransactionMember() {
      return this.selectItems.emptyItems.concat(this.requestData.transactionMember.map((e, idx) => {
        return {label: e.nickname, value: e.tsmId, key: `transaction-item-${idx}`}
      }))
    },

    mainItemList() {
      const result = []
      this.requestData.codeList.filter(e => e.useYn === 'Y').forEach((e) => {
        result.push(
          {
            value: e.itemId,
            label: e.itemName,
            key: `main-item-${e.itemId}`,
            groupValue: e.itemGroup1
          }
        )
      })
      return result
    },
    employeeList() {
      const result = []
      this.requestData.employees.forEach((e) => {
        result.push(
            {
              value: e.userId,
              label: e.userFullname,
              key: `employee-${e.userId}`,
            }
        )
      })
      return result
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.internalValue = newValue
      }
    },
    internalValue: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.$emit('input', newValue)
      }
    },
    accountId: {
      immediate: true,
      handler(newValue) {
        if (!newValue) return
        this.loadMembers()
      }
    },
    'internalValue.account.mainItemCode': {
      immediate: true,
      handler(newValue) {
        if(!newValue || newValue === 'null') return
        this.internalValue.account.mainItem = this.mainItemList.find(e => e.value === newValue).label
      }
    },
  },
  mounted() {
    this.internalValue = this.value
    this.loadMembers()
  },
  methods: {
    async loadMembers() {
      try {
        const requestList = []
        requestList.push(this.loadCmsMember())
        requestList.push(this.loadTaxMember())
        requestList.push(this.loadTransactionMember())
        requestList.push(this.$request(PATH.ITEM.LIST)
          .setObject({})
          .enqueue()
        )
        requestList.push(this.loadEmployees())
        const result = await Promise.all(requestList)
        this.requestData.cmsMember = result[0].data
        this.requestData.taxMember = result[1].data
        this.requestData.transactionMember = result[2].data
        this.requestData.codeList = result[3].data
        this.requestData.employees = result[4].data
      } catch (e) {
        console.error(e)
      }
    },
    async loadCmsMember() {
      const result = await this.$request(PATH.CMS.GET_CMS_MEMBERS).setObject({accountId: this.accountId}).enqueue()
      return result
    },
    async loadTaxMember() {
      const result = await this.$request(PATH.TAX.GET_TAX_MEMBERS).setObject({accountId: this.accountId}).enqueue()
      return result
    },
    async loadTransactionMember() {
      const result = await this.$request(PATH.TRANSACTION.GET_TRANSACTION_MEMBERS).setObject({accountId: this.accountId}).enqueue()
      return result
    },
    async loadEmployees() {
      const result = await this.$request(PATH.OPTIONS_LOAD_EMPLOYEE).get().enqueue()
      return result
    },

    async fileDownload(attach) {
      try {
        const url = `${PATH.BASE_URL}${PATH.RESOURCE.FILE}?path=${encodeURI(attach.path)}&filename=${encodeURI(attach.originalFilename)}`;
        window.open(url, "_blank");
      } catch (e) {
        console.error(e);
        this.$alert(this.t("파일 경로가 올바르지 않습니다."));
      }
    },

    onClickElectrical() {
      this.$emit('go-electrical', this.internalValue)
    },

    changeAddress(data) {
      if (!data) return
      this.internalValue.company.address = data.roadAddr || data.jibunAddr;
      this.internalValue.company.zipCode = data.zipNo;
      this.internalValue.company.addressDetail = null;
      this.internalValue.company.lat = data.lat;
      this.internalValue.company.lng = data.lng;
    },
  },
}
</script>

<style scoped>
.account_detail_list_wrap {
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  padding-bottom: 69px;
}
</style>
