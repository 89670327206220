<template>
  <div
    class="overflow-y-auto overflow-x-hidden flex flex-column"
  >
    <mobile-group-header :label="'작업 일지'" />
    <div class="item-sub-list-wrap">
      <div
        v-for="(item, index) in internalValue"
        :key="'item-' + index"
        class="item-sub-wrap"
      >
        <div class="item_wrap">
          <work-record-item
            :key="'sub-item-' + index"
            v-model="internalValue[index]"
            :work-type-options="workTypeOptions"
            class="sub-item"
            @delete-item="deleteItem(index)"
          />
        </div>
      </div>
      <template v-if="internalValue.length === 0">
        <empty-data :text="'조회된 내역이 없습니다.' | translate" />
      </template>
    </div>
    <div class="add-button-wrap">
      <button
        class="add-button"
        @click.stop="addRow"
      >
        {{ '작업일지 추가'| translate }}
      </button>
    </div>
  </div>
</template>

<script>
import emptyData from "@/components/part/EmptyData";
import workRecordItem from "@/views/home/WorkRecordItem";
import {DateTime} from "luxon";

export default {
  name: "WorkRecordList",
  components: {workRecordItem, emptyData},
  props: {
    rentalContractInfo: {
      type: Object,
      default: undefined,
    },
    value: undefined,
    workTypeOptions: undefined,
  },

  data() {
    return {
      internalValue: undefined,
      itemGroupCodeList: undefined,
      itemCodeList: undefined,
      dummy: {
        workReportMasterId: null,
        workReportId: null,
        workDate: null,
        startTime: this.setStartTime,
        endTime: this.setEndTime,
        worker: null,
        workerName: null,
        workState: null,
        accountId: null,
        account: undefined,
        accountName: null,
        address: null,
        workType: null,
        workTypeName: null,
        workContents: null,
        accountModel: null,
        accountItems: [],
        isCustomAccount: 'N',
        customAccountName: null,
      },
      searchOptions: {
        start: null,
        end: null,
        worker: null,
      }
    }
  },

  computed: {
    setStartTime() {
      if(!this.internalValue) return this.$now().startOf('hour').toFormat('HH:mm')
      if (this.internalValue.length < 1) {
        return this.$now().startOf('hour').toFormat('HH:mm')
      } else {
        return DateTime.fromISO(
            this.internalValue[this.internalValue.length - 1].endTime
        ).toFormat('HH:mm')
      }
    },

    setEndTime() {
      if(!this.internalValue) return this.$now().startOf('hour').plus({ hour: 1 }).toFormat('HH:mm')
      if (this.internalValue.length < 1) {
        return this.$now().startOf('hour').plus({ hour: 1 }).toFormat('HH:mm')
      } else {
        return DateTime.fromISO(
            this.internalValue[this.internalValue.length - 1].endTime
        )
            .plus({ hour: 1 })
            .toFormat('HH:mm')
      }
    },

  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.internalValue = newValue
        if (!this.internalValue || this.internalValue.length < 0) {
          this.internalValue = []
          this.addRow()
        }
      }
    },
    internalValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      }
    },
  },

  methods: {

    deleteItem(index) {
      if (this.internalValue[index].workReportId) {
        this.internalValue[index].deleteYn = 'Y'
        this.$emit("delete-item", this.internalValue[index])
      }
      this.internalValue.splice(index, 1)
    },

    addRow() {
      const addArr = {
        workReportMasterId: null,
        workReportId: null,
        workDate: null,
        startTime: this.setStartTime,
        endTime: this.setEndTime,
        worker: null,
        workerName: null,
        workState: null,
        accountId: null,
        account: undefined,
        accountName: null,
        address: null,
        workType: null,
        workTypeName: null,
        workContents: null,
        accountModel: null,
        accountItems: [],
        isCustomAccount: 'N',
        customAccountName: null,
      }
      this.internalValue.push(addArr)

    },

  },
}
</script>

<style scoped>
.add-button-wrap {
  background-color: #f3f3f3;
  padding: 12px;
}

.add-button {
  width: 100%;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fff;
  background-color: #0C98FE;
  padding: 8px 4px;
  border-radius: 3px;
}

.item-sub-list-wrap {
  padding-bottom: 14px;
  background-color: #f3f3f3;
}

.sub-item {
  background: #fff;
}

.item_wrap {
  background-color: #f3f3f3;
  padding: 6px 14px;
}
</style>
