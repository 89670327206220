<template>
  <div
    id="as_register_wrap_con"
    class="overflow-y-auto overflow-x-hidden"
  >
    <my-tool-bar
      :loading="loadingUpdate || loading"
      :action-mode="false"
      :title="title"
      :back-list="backToParam"
    >
      <template #buttons>
        <toolbar-save-action
          @save="onSave"
          @refresh="onRefreshClicked"
        />
      </template>
    </my-tool-bar>

    <div style="position: relative; top: 56px;">
      <template v-if="!loading">
        <m-form
          :key="key"
          ref="form"
          :edit="true"
          :form-data.sync="formData"
          :form-info="formInfo"
          class="overflow-y-auto overflow-x-hidden"
          form-provider="mps"
        />
      </template>
      <template v-else>
        <progress-loading />
      </template>
    </div>
    <over-max-size
      :base-param="formData"
      :detachments-list="[]"
      :attachments-list="[addFiles.attachments]"
    />
  </div>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import MForm from "@/assets/plugins/mps-form/MForm";
    import {CODE, MAXLENGTH, PATH} from "@/constants";
    import VuexProps from "@/framework/vuex/VuexProps";
    import DetailUtil from "@/components/mixins/DetailUtil";

    import ToolbarSaveAction from "@/components/app/toolbar/ToolbarSaveAction";
    import now from "@/assets/plugins/mps-common/time/now";

    import {ETC} from "@/constants/etc-code";
    import ProgressLoading from "@/components/part/ProgressLoading";
    import OverMaxSize from "@/components/app/toolbar/OverMaxSize.vue";
    import Permissions from "@/constants/permissions";

    const infoKeys = {
      account: "accountId",
      writeDate: "writeDate"
    }

    export default {
        name: "ConsultNoteRegister",
        components: {
          OverMaxSize,
            ProgressLoading,
            ToolbarSaveAction,
            MForm, MyToolBar
        },
        extends: WindowComponent,
        mixins: [VuexProps, DetailUtil],
        props: {
          account:undefined,
          entryPoint: undefined
        },
        data() {
            return {
                key: 0,
                loading: false,
                loadingUpdate: false,
                // Form 컴포넌트의 더티 체크를 피하기 위해 toggle 을 두어 초기화 시킬 때마다 값을 변경한다.
                toggleUpdate: false,
                addFiles: {
                  attachments: null,
                },
                formData: undefined,
                item: undefined,
              formInfo: [
                {
                  mutable: false,
                  title: this.$translate('거래처명'),
                  key: infoKeys.account,
                  read: (inEditMode, data) => null,
                  write: (data, v) => {
                    if (!v) return;
                    data.accountId = v.accountId;
                    data.accountName = v.accountName;
                  },
                  formReadType: this.$mps.form.types.ACCOUNT_PICKER,
                  formWriteType: this.$mps.form.types.ACCOUNT_PICKER,
                  options: {}
                },
                {
                  mutable: true,
                  title: this.$translate('상담일'),
                  key: infoKeys.writeDate,
                  write: (data, v) => {
                    let d = this.$parseDate(v);
                    data.writeDate = !!d ? d.toISO() : null;
                  },
                  formReadType: this.$mps.form.types.DATE_PICKER,
                  formWriteType: this.$mps.form.types.DATE_PICKER,

                },
                {
                  mutable: true,
                  title: this.$translate('상담내용'),
                  key: 'content',
                  formReadType: this.$mps.form.types.FILE_UPLOAD,
                  formWriteType: this.$mps.form.types.FILE_UPLOAD,
                  updateData: (data, v) => {
                    this.addFiles.attachments = data.attachments;
                  },
                  options: {
                    listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
                    accept: ETC.ACCEPT.IMAGE,
                  },
                },
              ],
            }
        },
      computed: {
        backToParam() {
          if(this.entryPoint === 'account') {
            return {
              expanded: true,
              key: 5,
              accountId: this.account.accountId
            }
          } else return null
        }
      },
        watch: {
            formData: {
                deep: true,
                handler: function () {
                    this.updateOptions();

                }
            },
        },
      created() {
        localStorage.setItem('ACCOUNT_DETAIL_TAB', 5)
      },
        mounted() {
            this.formData = {
              accountId: this.account.accountId,
              accountName: this.account.accountName,
              writeDate: this.$now().toISODate(),
              content: null,
              attachments: []
            }
        },
        methods: {
          updateOptions() {
            // 거래처 피커
            this.updateOptionByKey(infoKeys.account, () => {
              return {
                accountId: this.formData.accountId,
                accountName: this.formData.accountName
              }
            });
          },

            /**
             * A/S 등록후 첨부파일을 초기화한다.
             */
            resetOptionsForFileUploadForm() {
                this.addFiles.attachments = null;

                this.updateOptionByKey(infoKeys.content, () => {
                    return {
                        attachments: this.toggleUpdate,
                        listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
                        accept: ETC.ACCEPT.IMAGE,
                    }
                });
                this.toggleUpdate = !this.toggleUpdate;
            },

            async requestSave() {
                this.loadingUpdate = true;
                try {
                    const response = await this.$requestWithOverlay(PATH.CONSULT_NOTE.REGISTER)
                        .multipart()
                        .appendObject('context', {
                          ...this.formData,
                        })
                        .appendFiles("attachments", this.addFiles.attachments)
                        .enqueue();
                    this.resetOptionsForFileUploadForm();
                    this.$snackbar(this.$translate("등록되었습니다."));
                    this.onClickToList(response)
                } catch (e) {
                    this.$snackbarError(this.$translate("서버에서 에러가 발생하였습니다."));
                }
                this.loadingUpdate = false;
            },
          onClickToList() {

            const param = {
              name: 'AccountDetail',
              params: {
                backExpanded: true,
                backKey:5,
                accountId: this.account.accountId
              }
            }
            this.routerManager.back()
            // this.$router.go(-2)
            // this.routerManager.push(param);
            // this.backGuard.pop();
          },

            async onSave() {
                if (!await this.$refs.form.validate()) return;

                  this.requestSave()

            },


            onRefreshClicked() {
                this.$dialog()
                    .message(this.$translate('편집중인 정보를 초기화합니다.'))
                    .buttonNegative(this.$translate('취소'))
                    .buttonPositive(this.$translate('초기화'), () => {
                        this.clearFormData();
                    })
                    .build().show();
            },

        }
    }
</script>

<style scoped>
</style>
